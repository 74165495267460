
import axios from "axios";
import clsx from "clsx";
import collect from "collect.js";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ResultItem } from "../../redux/ResultSlice";
import { MYSVG } from "../../shared/Helpers/MYSVG";

type props = {
    setResultFilter: any,
    resultSummary: any,
    setSortField: any,
    setSortDir: any,
    sortField: any,
    sortDir: any,
    extraFilterHandle: any,
    pageHandle: any,
    resultFilter: any,
    extraFilter: any
}
export const AnalyseAside: FC<props> = (props) => {

    const urlParams = useParams();
    const navigate = useNavigate();
    var fileDownload = require('js-file-download');
    const [isLoading, setLoading] = useState(false)

    const downloadCsv = () => {
        setLoading(true)
        axios.post("https://compare.hoteldoping.com/arg/api/result/list/" + urlParams.id, {
            analyse_id: urlParams.id,
            scan_start: urlParams.scan_start,
            excelExport: true
        }, {
            responseType: 'blob'
        }).then(function (res) {
            fileDownload(res.data, 'export.xlsx');
            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    const [filters, setFilters] = useState([])
    useEffect(() => {

        const analyse_id = urlParams.id;

        axios.post("https://compare.hoteldoping.com/arg/api/result/getDateGroupByAnalyse/" + analyse_id, {
            analyse_id: urlParams.id,
            scan_start: urlParams.scan_start,
        }).then(r => {
            setFilters(r.data);
            if (r.data.length > 0) {
                var firstDate = r.data[0];
                props.setResultFilter({
                    adult_count: firstDate.adult_count,
                    start_date: firstDate.start_date,
                    end_date: firstDate.end_date,
                })
            }
        })
        console.log("Filter Groups Loaded")
    }, [urlParams])

    const dateFormat = (date: string) => (
        DateTime.fromISO(date).toFormat('dd LLL, cccc', { locale: 'tr' })
    );

    const night = (r: any) => (
        DateTime.fromISO(r.end_date).diff(DateTime.fromISO(r.start_date), 'days').days
    );

    const handleAnalyseSelect = (r: ResultItem) => {
        const analyse_id = r.analyse_id
        const scan_start = r.scan_start
        navigate("/analyse/result/" + analyse_id + "/" + scan_start)
    }

    return (<>
        <h3 className="bigTitle  text-sky-700">
            <MYSVG path='/assets/svg/settings.svg' />
            <span className='ml-2'>Seçenekler</span>
        </h3>

        <div className="my-8">

            <div className="flex rounded-md  text-black  transition-shadow ease-in-out">
                <div className="w-full flex flex-wrap">

                    <h1 className="border-b  mb-3 w-full flex flex-nowrap place-items-center justify-between text-md font-semibold text-gray-600  pb-4">
                        <div className="flex flex-nowrap place-items-center ">
                            <MYSVG path='/assets/svg/calendar.svg' className="mr-2" svgClassName="h-6 w-6" />Analizi Yapılan Tarihler
                        </div>
                    </h1>

                    <div className="w-full text-sm font-medium text-gray-400 grid grid-cols-2 gap-2">
                        {filters && filters.map((r: any) => (
                            <div onClick={() => {
                                props.setResultFilter({
                                    adult_count: r.adult_count,
                                    start_date: r.start_date,
                                    end_date: r.end_date,
                                })
                            }} className={clsx("asideMiniButton",
                                (props.resultFilter?.start_date == r.start_date &&
                                    props.resultFilter?.end_date == r.end_date &&
                                    props.resultFilter?.adult_count == r.adult_count) ?
                                    "active" : ""
                            )}>
                                {dateFormat(r.start_date)}<br />
                                <small className="">{night(r)} Gece - {r.adult_count} Yetişkin</small>
                            </div>
                        ))}
                    </div>
                    <h1 className="border-b mt-6 mb-3 w-full flex flex-nowrap place-items-center justify-between text-md font-semibold text-gray-600  pb-4">
                        <div className="flex flex-nowrap place-items-center ">
                            <MYSVG path='/assets/svg/funnel.svg' className="mr-2" svgClassName="h-6 w-6" />Filtrele
                        </div>
                    </h1>
                    <div className="w-full text-sm font-medium text-gray-400 grid grid-cols-2 gap-2">
                        <div onClick={() => { props.extraFilterHandle({}); props.pageHandle(1) }} className={clsx("asideMiniButton",
                            (!props.extraFilter?.compare_status) ? "active" : ""
                        )}>
                            Hepsi<br />
                            <small> Hepsi</small>
                        </div>
                        <div onClick={() => { props.extraFilterHandle({ compare_status: 'lower' }); props.pageHandle(1) }} className={clsx("asideMiniButton",
                            (props.extraFilter?.compare_status == "lower") ? "active" : ""
                        )}>
                            Sadece Ucuzlar<br />
                            <small> Sadece Ucuzlar</small>
                        </div>
                        <div onClick={() => { props.extraFilterHandle({ compare_status: 'higher' }); props.pageHandle(1) }} className={clsx("asideMiniButton",
                            (props.extraFilter?.compare_status == "higher") ? "active" : ""
                        )}>
                            Sadece Pahalılar<br />
                            <small> Sadece Pahalılar</small>
                        </div>
                        <div onClick={() => { props.extraFilterHandle({ compare_status: 'equal' }); props.pageHandle(1) }} className={clsx("asideMiniButton",
                            (props.extraFilter?.compare_status == "equal") ? "active" : ""
                        )}>
                            Sadece Eşitler<br />
                            <small> Sadece Eşitler</small>
                        </div>
                        <div onClick={() => { props.extraFilterHandle({ compare_status: 'notavail' }); props.pageHandle(1) }} className={clsx("asideMiniButton",
                            (props.extraFilter?.compare_status == "notavail") ? "active" : ""
                        )}>
                            Sadece Fiyatsızlar<br />
                            <small> Sadece Fiyatsızlar</small>
                        </div>
                        <div onClick={() => { props.extraFilterHandle({ compare_status: 'unknown' }); props.pageHandle(1) }} className={clsx("asideMiniButton",
                            (props.extraFilter?.compare_status == "unknown") ? "active" : ""
                        )}>
                            Tüm Kanallarda Dolu<br />
                            <small> Durumu Bilinmiyor</small>
                        </div>
                    </div>

                    <h1 className="border-b mt-6 mb-3 w-full flex flex-nowrap place-items-center justify-between text-md font-semibold text-gray-600  pb-4">
                        <div className="flex flex-nowrap place-items-center ">
                            <MYSVG path='/assets/svg/arrowUpDown.svg' className="mr-2" svgClassName="h-6 w-6" />Sıralama Kriteri
                        </div>
                    </h1>
                    <div className="w-full text-sm font-medium text-gray-400 grid grid-cols-2 gap-2 mb-2">
                        <div onClick={() => { props.setSortField("tb_rate_by_lower") }} className={clsx("asideMiniButton",
                            (props.sortField == "tb_rate_by_lower") ? "active" : ""
                        )}>
                            Pahalılık Oranı<br />
                            <small> Alan</small>
                        </div>
                        <div onClick={() => { props.setSortField("property_priority") }} className={clsx("asideMiniButton",
                            (props.sortField == "property_priority") ? "active" : ""
                        )}>
                            Önem Sırası<br />
                            <small> Alan</small>
                        </div>
                    </div>
                    <h1 className="border-b mt-6 mb-3 w-full flex flex-nowrap place-items-center justify-between text-md font-semibold text-gray-600  pb-4">
                        <div className="flex flex-nowrap place-items-center ">
                            <MYSVG path='/assets/svg/arrowUpDown.svg' className="mr-2" svgClassName="h-6 w-6" />Sıralama Yönü
                        </div>
                    </h1>
                    <div className="w-full text-sm font-medium text-gray-400 grid grid-cols-2 gap-2">
                        <div onClick={() => { props.setSortDir("asc nulls last") }} className={clsx("asideMiniButton",
                            (props.sortDir == "asc nulls last") ? "active" : ""
                        )}>
                            Artan<br />
                            <small> Sıralama Yönü</small>
                        </div>
                        <div onClick={() => { props.setSortDir("desc nulls last") }} className={clsx("asideMiniButton",
                            (props.sortDir == "desc nulls last") ? "active" : ""
                        )}>
                            Azalan<br />
                            <small> Sıralama Yönü</small>
                        </div>
                    </div>

                    <h1 className="border-b mt-6 mb-3 w-full flex flex-nowrap place-items-center justify-between text-md font-semibold text-gray-600  pb-4">
                        <div className="flex flex-nowrap place-items-center ">
                            <MYSVG path='/assets/svg/download.svg' className="mr-2" svgClassName="h-6 w-6" />Export
                        </div>
                    </h1>

                    {!isLoading &&
                        (<div className="w-full text-sm font-medium text-gray-400 items-center" onClick={() => downloadCsv()}>
                            <div className={clsx("p-2 flex flex-nowrap place-items-center gap-1 border border-blue-200 rounded-md shadow-sm hover:shadow-md hover:shadow-blue-100 text-gray-600 bg-slate-50 hover:border-solid cursor-pointer ")}>
                                <MYSVG path='/assets/svg/file.svg' svgClassName="h-4 w-4" /> <span> Excel Export</span>
                            </div>
                        </div>)
                    }
                    {isLoading &&
                        (<div className="w-full text-sm font-medium text-gray-400 items-center">
                            <div className={clsx("p-2 flex flex-nowrap place-items-center gap-1 border border-blue-200 rounded-md shadow-sm hover:shadow-md hover:shadow-blue-100 text-gray-600 bg-gray-50 hover:border-solid cursor-pointer ")}>
                                <MYSVG path='/assets/svg/file.svg' svgClassName="h-4 w-4" /> <span> Hazırlanıyor...</span>
                            </div>
                        </div>)
                    }
                </div>
            </div>

        </div>

    </>)

}