import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"

interface PropertyInventory {
    search_parameters: []
    title: string
    property_priority: number
    property_note: string
    active_channels: string[]
}

export interface ResultItem {
    id: number
    analyse_id: number
    scan_start: Date
    inventory_id: number
    start_date: string
    end_date: string
    adult_count: number
    tb_rate_by_lower?: number
    tb_diff_by_lower?: number
    tatilbudur_com: number
    m_tatilbudur_com: number
    otelz_com?: number
    m_otelz_com?: number
    tatilsepeti_com: number
    etstur_com?: number
    odamax_com?: number
    jollytur_com?: number
    hotels_com?: number
    handicap_json: {}
    found_dt_json: {}
    low_ch: string
    high_ch: string
    compare_status: string
    created_at: Date
    updated_at: Date
    PropertyInventory: PropertyInventory
    trivago_tatilbudur_com: number
    trivago_agoda_com: number
    trivago_bookandgo_com: number
    trivago_trip_com: number
    trivago_zenhotels_com: number
    trivago_destinia_com: number
    trivago_top_channel: string
    trivago_min_channel: string
    trivago_top_price: number
    trivago_min_price: number
    trivago_tb_status: string
}

export interface ResultResponse {
    total: number
    items: ResultItem[]
    cacheKey: string
}
export interface Paginator {
    page: number
    pageSize: number
}
export interface ResultFilter {
    adult_count: number
    start_date: Date
    end_date: Date
}
export interface ResultQueryParams {
    analyse_id: string
    scan_start: string
    sorting: any
    paginator: Paginator
    searchTerm: string | undefined
    filter: any
}

interface ResultState {
    data: ResultResponse | null
    loading: boolean
    error: string
}

const initialState: ResultState = {
    data: null,
    loading: false,
    error: "",
}

const fetchResult = createAsyncThunk("fetchResult", async (parameters: ResultQueryParams) => {
    const response = await axios.post<ResultResponse>(
        "https://compare.hoteldoping.com/arg/api/result/list/" + parameters.analyse_id,
        parameters
    )
    return response.data
})

const resultSlice = createSlice({
    name: "result",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchResult.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })

        builder.addCase(fetchResult.fulfilled, (state, action: PayloadAction<ResultResponse>) => {
            state.loading = false
            state.data = action.payload
        })

        builder.addCase(fetchResult.rejected, (state, action) => {
            state.loading = false
            state.error = "Error fetch data"
        })
    },
})

export default resultSlice.reducer
export { fetchResult }
