import { FC } from 'react';
import { useAppSelector } from '../../../hooks';
import AnalysisInfo from './Steps/AnalysisInfo';
import DateSelector from './Steps/DateSelector';
import PropertySelector from './Steps/PropertySelector';

const WizardContent: FC = () => {
    const step = useAppSelector((state) => state.analysis.formStep);

    if (step === 0) {
        return (
            <AnalysisInfo />
        )
    } else if (step === 1) {
        return (
            <DateSelector />
        )
    }
    else if (step === 2) {
        return (
            <PropertySelector />
        )
    }

    return null
}

export default WizardContent
