import { combineReducers, configureStore } from '@reduxjs/toolkit'
import propertyReducer from './redux/PropertySlice'
import layoutReducer from './redux/LayoutSlice'
import authReducer from './redux/AuthSlice'
import timeReducer from './redux/TimeSlice'
import toastReducer from "./redux/ToastSlice";
import popUpReducer from './redux/PopUpSlice'
import storage from 'redux-persist/lib/storage';
import analysisReducer from './redux/AnalysisSlice';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import  inventorReducer  from './redux/InventorySlice'
import resultReducer from "./redux/ResultSlice"

const persistConfig = {
  key: 'root_v1.0.1',
  storage,
}

const rootReducer = combineReducers({
  property: propertyReducer,
  inventory: inventorReducer,
  layout: layoutReducer,
  auth: authReducer,
  time: timeReducer,
  analysis: analysisReducer,
  toast: toastReducer,
  popUp: popUpReducer,
  result: resultReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {        
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], 
      },
    }),
  devTools: true,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)