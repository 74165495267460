import * as PropTypes from "prop-types"
import * as React from "react"
import classnames from "classnames"

const ClockSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 13 13">
            <path
                fill="#2B2B2B"
                fillRule="evenodd"
                d="M6.606 2.194a4.487 4.487 0 110 8.974 4.487 4.487 0 010-8.974zm0 1.496a.748.748 0 00-.747.747v2.244c0 .198.078.388.219.529l1.495 1.495a.748.748 0 001.058-1.057L7.354 6.37V4.437a.748.748 0 00-.748-.747z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}
const TickkSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 13 13">
            <path
                fill="#2B2B2B"
                fillRule="evenodd"
                d="M6.719 2.194a4.487 4.487 0 110 8.974 4.487 4.487 0 010-8.974zm1.714 2.462L5.971 7.12l-.967-.967A.748.748 0 103.946 7.21l1.496 1.495a.748.748 0 001.058 0l2.99-2.991a.748.748 0 00-1.057-1.058z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}
const StarSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 10 10">
            <path
                fill="#FBBF24"
                fillRule="evenodd"
                d="M4.581 8.036L2.639 9.103a.525.525 0 01-.725-.235.583.583 0 01-.054-.356l.371-2.26a.577.577 0 00-.154-.496l-1.571-1.6a.579.579 0 01-.01-.793.529.529 0 01.307-.164l2.172-.33a.539.539 0 00.404-.306l.97-2.056a.526.526 0 01.963 0l.97 2.056c.079.165.23.28.405.306l2.171.33c.293.045.497.33.454.636a.57.57 0 01-.156.32l-1.572 1.6a.577.577 0 00-.154.497l.371 2.26a.559.559 0 01-.438.647.516.516 0 01-.34-.056L5.08 8.036a.516.516 0 00-.499 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}
const PlusSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
            <path
                fill="#202532"
                fillRule="evenodd"
                d="M7.775 1.987a5.983 5.983 0 110 11.965 5.983 5.983 0 010-11.965zm0 1.496a4.487 4.487 0 100 8.973 4.487 4.487 0 000-8.973zm0 1.495c.413 0 .748.335.748.748v1.496h1.496a.748.748 0 010 1.495H8.523v1.496a.748.748 0 01-1.495 0V8.717H5.532a.748.748 0 110-1.495h1.496V5.726c0-.413.334-.748.747-.748z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}
const OptionsSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
            <path
                fill="#212121"
                fillRule="evenodd"
                d="M8.04 11.026a1.496 1.496 0 110 2.991 1.496 1.496 0 010-2.99zm0-4.487a1.496 1.496 0 110 2.991 1.496 1.496 0 010-2.99zm0-4.487a1.496 1.496 0 110 2.992 1.496 1.496 0 010-2.992z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export const TestComponent = () => {
    const badgeClass = "flex place-items-center gap-1 rounded-lg bg-gray-50 py-1.5 px-2"

    return (
        <>
            <div className="h-[400px]"></div>

            <div className="interFont inline-block rounded-lg bg-white shadow-sm">
                <div className="rounded-tl-md rounded-tr-md bg-sky-600 text-sm text-white">
                    <div style={{ backgroundImage: "url(/headerbg.png)" }} className="py-5 px-8">
                        <img src="/headerimage.svg" alt="headerimage" />
                    </div>
                </div>

                <div className="p-3  text-gray-700">

                    <div className="inline-block rounded-sm bg-amber-200 py-0.5 px-1.5 text-center text-xs font-semibold uppercase">
                        New
                    </div>

                    <div className="my-3 text-xs font-medium text-gray-800">Course Name Number One</div>
                    <div className="mt-1 flex gap-3 text-xs font-medium">
                        <div className={badgeClass}>
                            <ClockSvg /> 3 Weeks
                        </div>
                        <div className={badgeClass}>
                            <TickkSvg /> $3,500
                        </div>
                        <div className={badgeClass}>
                            <StarSvg /> 4.8
                        </div>
                    </div>
                </div>

                <div className="mt-4 flex p-3 text-gray-800">
                    <div className="flex place-items-center gap-1 text-xs font-semibold">
                        <PlusSvg />
                        Add to My List
                    </div>
                    <div className="ml-auto flex place-items-center gap-1 text-xs font-semibold">
                        <div className="flex h-9 place-items-center rounded-md border border-gray-600 py-1.5 px-2.5">
                            <OptionsSvg />
                        </div>
                        <div className="flex h-9 place-items-center rounded-md  border border-green-600 px-3.5 text-green-600">
                            Start Now
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
