import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Analysis, AnalyseStates, Properties } from "../models/analysis";

const initialState: AnalyseStates = {
    // show detail
    selected_analyse: undefined,

    // index-analiz listesi göstermek için
    items: [],

    // Form-post işlemleri için
    formStep: 0,
    title: "",
    period_repeat: 2,
    adultCount: 2,
    period_start: "10:00",
    date_others: [],
    date_today: 0,
    date_tomorrow: 0,
    date_weekend: 0,
    property_ids: [],
    period_days: [],
    period_times: []
}
export const fetchAnalysis = createAsyncThunk('analysis/fetch',
    async (params, { getState }) => {
        const state: any = getState();
        return await axios.post("https://compare.hoteldoping.com/arg/api/analysis/list", params).then(result => {
            if (result.data.total > 0) {
                return result.data.items;
            }
        });
    }
)
export const analysisSlice = createSlice({
    name: "analysis",
    initialState,
    reducers: {
        setSelectedAnalyse(state, { payload }) {
            console.log("pay", payload)
            state.selected_analyse = payload as Analysis
        },
        reset: (state) => {
            state.adultCount = 2;
            state.date_others = [];
            state.date_today = 0;
            state.date_tomorrow = 0;
            state.date_weekend = 0;
            state.property_ids = [];
            state.formStep = 0;
            state.title = "";
            state.period_repeat = 2;
            state.period_start = "10:00";
            state.period_days = [];
            state.period_times = [];
        },
        getById: (state, { payload }) => {
            state.date_others = payload.date_others;
            state.date_today = payload.date_today;
            state.date_tomorrow = payload.date_tomorrow;
            state.date_weekend = payload.date_weekend;
            state.period_repeat = payload.period_repeat;
            state.period_start = payload.period_start;
            state.property_ids = payload.property_ids;
            state.title = payload.title;
            state.period_days = payload.period_days;
            state.period_times = payload.period_times;
        },
        changeFormStep: (state, { payload }) => {
            state.formStep = payload;
        },
        setName: (state, { payload }) => {
            state.title = payload;
        },
        setStartTime: (state, { payload }) => {
            state.period_start = payload;
        },
        setPeriod: (state, { payload }) => {
            state.period_repeat = payload;
        },
        setToday: (state, { payload }) => {
            state.date_today = payload
        },
        setTomorrow: (state, { payload }) => {
            state.date_tomorrow = payload
        },
        setWeekend: (state, { payload }) => {
            state.date_weekend = payload
        },
        setAdultCount: (state, { payload }) => {
            state.adultCount = payload;
        },
        addMoreDateItem(state) {
            state.date_others.push({ title: "", startDate: "", endDate: "" })
        },
        updateDateItemTitle: (state, { payload }) => {
            state.date_others[payload.indexInfo].title = payload.dateInfo
        },
        updateDateItemStartDate: (state, { payload }) => {
            state.date_others[payload.indexInfo].startDate = payload.dateInfo
        },
        updateDateItemEndDate: (state, { payload }) => {
            state.date_others[payload.indexInfo].endDate = payload.dateInfo
        },
        removeDateItem: (state, { payload }) => {
            state.date_others.splice(payload, 1);
        },
        addPropertyItem: (state, { payload }) => {
            state.property_ids?.push(payload);
        },
        addAllPropertyItems: (state, { payload }) => {
            payload && payload.map((pay: any) => {
                if (state.property_ids?.find(f => f.id == pay.id) == null) {
                    state.property_ids?.push({
                        id: pay.id,
                    });
                }
            })
        },
        removePropertyItem: (state, { payload }) => {
            const index = state.property_ids?.findIndex((r: any) => r.id === payload);
            if (index !== undefined) {
                state.property_ids?.splice(index, 1)
            }
        },
        removeAllPropertyItems: (state, { payload }) => {
            state.property_ids?.splice(0, state.property_ids?.length)
        },
        setPeriodDays: (state, { payload }) => { 
            if (state.period_days == null)
                state.period_days = []
            if (payload)
                if (state.period_days.some((s: any) => s.payload) == false)
                    state.period_days.push(payload);
        },
        removePeriodDays: (state, { payload }) => {
            payload && state.period_days.splice(state.period_days.indexOf(payload), 1);
        },
        setPeriodTimes: (state, { payload }) => {
            if (state.period_times == null)
                state.period_times = []
            payload && state.period_times.some((s: any) => s.payload) == false && state.period_times.push(payload);
        },
        removePeriodTimes: (state, { payload }) => {
            payload && state.period_times.splice(state.period_times.indexOf(payload), 1);
        }

    },
    extraReducers: (builder) => {

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchAnalysis.fulfilled, (state, action) => {
            state.items = action.payload;
        })
    },
})

export const {
    setSelectedAnalyse,
    setName,
    setPeriod,
    setStartTime,
    setAdultCount,
    setToday,
    setTomorrow,
    setWeekend,
    addMoreDateItem,
    changeFormStep,
    updateDateItemTitle,
    updateDateItemStartDate,
    updateDateItemEndDate,
    removeDateItem,
    addPropertyItem,
    addAllPropertyItems,
    removeAllPropertyItems,
    removePropertyItem,
    getById,
    reset,
    setPeriodDays,
    removePeriodDays,
    setPeriodTimes,
    removePeriodTimes
} = analysisSlice.actions

export default analysisSlice.reducer;
