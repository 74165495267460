import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { PropertyFilter, PropertyPaginateFilter, PropertyState } from '../models/properties';


const initialState = {
  total: 0,
  isLoading: false,
  error: false,
  items: [],
  withPrice: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDay(),
  search: undefined,
  paginator: {
    page: 1,
    pageSize: 10
  }
} as PropertyState

export const fetchProperties = createAsyncThunk('properties/fetch',
  async (withPriceDate: string, { getState, dispatch }) => {
    const state: any = getState();

    dispatch(setLoading(true))
    dispatch(setWithPrice(withPriceDate))

    let params = {
      sorting: {
        column: "property_priority",
        direction: "asc"
      },
      withPrice: withPriceDate,
      searchTerm: state.property.search
    };

    return await axios.post("https://compare.hoteldoping.com/arg/api/propertyInventory/list", params).then((result) => {

      dispatch(setLoading(false));

      if (result.data.total > 0)
        return result.data.items;

    });

  }
)
export const fetchPropertiesWithPagination = createAsyncThunk('properties/fetch',
  async (parameters: PropertyPaginateFilter, { dispatch }) => {
    dispatch(setLoading(true))
    dispatch(setWithPrice(parameters.withPriceDate))
    let params: any = {};
    if (parameters.id !== undefined) {
      params = {
        analysisId: parameters.id,
        sorting: {
          column: "property_priority",
          direction: "asc"
        },
        withPrice: parameters.withPriceDate,
        searchTerm: parameters.searchTerm,
        paginator: parameters.paginator
      };

      return await axios.post(`https://compare.hoteldoping.com/arg/api/propertyInventory/list`, params).then((result) => {

        dispatch(setLoading(false));
        dispatch(setTotal(result.data.total));

        if (result.data.total > 0)
          return result.data.items;

      });
    }

  }
)
export const fetchPropertiesWithId = createAsyncThunk('properties/fetch',
  async (parameters: PropertyFilter, { getState, dispatch }) => {
    const state: any = getState();

    dispatch(setLoading(true))
    dispatch(setWithPrice(parameters.withPriceDate))

    let params = {
      analysisId: parameters.id,
      sorting: {
        column: "property_priority",
        direction: "asc"
      },
      withPrice: parameters.withPriceDate,
      searchTerm: state.property.search,


    };

    return await axios.post(`https://compare.hoteldoping.com/arg/api/propertyInventory/list`, params).then((result) => {

      dispatch(setLoading(false));

      if (result.data.total > 0)
        return result.data.items;

    });

  }
)

export const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setProperties: (state, { payload }) => {
      state.items = payload;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setWithPrice: (state, { payload }) => {
      state.withPrice = payload;
    },
  },
  extraReducers: (builder) => {
    /*
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchProperties.fulfilled, (state, action) => {
      // Add user to the state array
      state.items = action.payload
    })*/
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchPropertiesWithId.fulfilled, (state, action) => {
      // Add user to the state array
      state.items = action.payload
    })
  },
})

// Action creators are generated for each case reducer function
export const { setProperties, setLoading, setWithPrice, setTotal } = propertySlice.actions

export default propertySlice.reducer
