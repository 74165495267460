import axios from 'axios';
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { fetchAnalysis } from '../../redux/AnalysisSlice';
import { closeRemovePopUp } from '../../redux/PopUpSlice';
import { showToast } from '../../redux/ToastSlice';

const PopUpComponent = () => {
    const pop = useAppSelector(state => state.popUp);
    const dispatch = useAppDispatch();

    const handleRemove = () => {
        axios.delete(`https://compare.hoteldoping.com/arg/api/analysis/destroy/${pop.removePopUp.removedId}`).then(result => {
            dispatch(fetchAnalysis());
            dispatch(closeRemovePopUp());
            dispatch(showToast({ status: "danger", message: "Removed analysis data" }));
        });

    }
    if (pop.removePopUp.status) {
        return (
            <div id="popup-modal" tabIndex={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full justify-center items-center flex" aria-modal="true" role="dialog">
                <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                    <div className="relative bg-blue-500 rounded-lg shadow">
                        <button
                            onClick={() => dispatch(closeRemovePopUp())}
                            type="button" className="absolute top-3 right-2.5 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="popup-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-6 text-center">
                            <svg aria-hidden="true" className="mx-auto mb-4 w-14 h-14 text-white " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <h3 className="mb-5 text-lg font-semibold text-white ">{pop.removePopUp.question}</h3>
                            <button
                                onClick={() => handleRemove()}
                                data-modal-toggle="popup-modal" type="button" className="text-slate-700 bg-slate-300 hover:bg-slate-400 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                Evet
                            </button>
                            <button
                                onClick={() => dispatch(closeRemovePopUp())}
                                data-modal-toggle="popup-modal" type="button" className="text-slate-700 bg-slate-300 hover:bg-slate-400 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">Hayır</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return <></>
}

export default PopUpComponent
