import debounce from "lodash.debounce";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchInventoriesWithPagination } from "../../redux/InventorySlice";
import { fetchPropertiesWithPagination, propertySlice } from "../../redux/PropertySlice";
import "./Property.scss";

type Props = {
    search: string,
    setSearch: any,
    setCurrentPage: any,
    searchRef: any
}
const Searchbar: FC<Props> = ({
    search,
    setSearch,
    setCurrentPage,
    searchRef
}) => {
    const url = useParams();
    const selectedDate = useAppSelector((state) => state.property.withPrice)

    const handleSearch = (event: any) => {
        if (event.target.value.length == 0 || event.target.value.length >= 3)
            setSearch(event.target.value);

    }
    const debouncedSearch = debounce(handleSearch, 500);

    return (
        <>
            <div className="relative text-gray-600 focus-within:text-gray-400 mb-5">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <i className="p-1 focus:outline-none focus:shadow-outline">
                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </i>
                </span>
                <input
                    onChange={debouncedSearch}
                    ref={searchRef}
                    type="search" placeholder="Ara..." className="pl-10 block w-full px-4 py-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" name="search" />
            </div>
        </>
    )
}

export { Searchbar };

