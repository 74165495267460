import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { hideMailPopup, setMailPopUp, setMailSpinner } from '../../redux/PopUpSlice';
import { showToast } from '../../redux/ToastSlice';
import Spinner from '../../shared/Spinner/Spinner';

const PopUpMail = () => {
    const popUpMail = useAppSelector((state) => state.popUp.mailPopUp);
    const auth = useAppSelector((state) => state.auth.user);
    const [mailInfos, setMailInfos] = useState({ mailAddress: "", mailSubject: "", mailText: "", replyTo: "" });
    useEffect(() => {

        setMailInfos({
            mailAddress: popUpMail.to,
            mailSubject: popUpMail.subject,
            mailText: popUpMail.msg,
            replyTo: auth.email
        });

    }, [popUpMail])

    const dispatch = useAppDispatch();
    const handleSendMail = () => {
        dispatch(setMailSpinner(true));
        mailInfos && axios.post("https://compare.hoteldoping.com/arg/api/smtp/send", mailInfos).then((result) => {
            result.status == 200 && dispatch(hideMailPopup());
            dispatch(setMailSpinner(false));
            dispatch(showToast({ status: "success", message: "Mail gönderildi" }));
        }).catch(() => {
            dispatch(setMailSpinner(false));
            dispatch(showToast({ status: "danger", message: "Mail gönderilemedi" }));
        })
    }
    return (
        <div className={"fixed md:inset-0 justify-center items-center flex"} aria-modal="true" role="dialog">
            <div className=" p-4 w-full max-w-xl h-full md:h-auto">
                <div className=" relative bg-white rounded-lg shadow w-full mb-3">

                    <div className='flex '>
                        <h3 className='font-semibold mt-4 ml-4 text-xl text-gray-600'>Parite Maili</h3>
                        <button onClick={() => dispatch(hideMailPopup())}
                            type="button" className=" m-3 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="popup-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div>
                        {popUpMail.isSending && (<div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" '>
                            <Spinner />
                        </div>)}

                        <div className='p-4'>
                            <div className='text-sm font-semibold mb-2'>
                                <label>Kime:</label>
                            </div>
                            <div>
                                <input placeholder='Kime'
                                    value={mailInfos.mailAddress}
                                    defaultValue={popUpMail.to}
                                    onChange={(e) => setMailInfos({ ...mailInfos, mailAddress: e.target.value })}
                                    className='bg-slate-100 p-1 rounded-md w-full border border-gray-400 outline-none focus:ring-1 focus:ring-blue-500' />
                            </div>
                        </div>
                        <div className='pt-4 px-4'>
                            <div className='text-sm font-semibold mb-2'>
                                <label>Konu</label>
                            </div>
                            <div>
                                <input
                                    placeholder='Konu'
                                    value={mailInfos.mailSubject}
                                    onChange={(e) => setMailInfos({ ...mailInfos, mailSubject: e.target.value })}
                                    className='bg-slate-100 p-1 rounded-md w-full border border-gray-400 outline-none focus:ring-1 focus:ring-blue-500' />
                            </div>
                        </div>
                        <div className='p-4'>
                            <div className='text-sm font-semibold mb-2'>
                                <label>Metin</label>
                            </div>
                            <div>
                                <textarea
                                    placeholder='Metin'
                                    onChange={(e) => setMailInfos({ ...mailInfos, mailText: e.target.value })}
                                    rows={17}
                                    value={mailInfos.mailText}
                                    className='bg-slate-100 p-1 rounded-md w-full border border-gray-400 outline-none focus:ring-1 focus:ring-blue-500' />
                            </div>
                        </div>
                        <div className='p-4'>
                            <button
                                onClick={() => handleSendMail()}
                                className='w-full border py-2 px-5 bg-blue-700 hover:bg-blue-800 text-white rounded-md'>Gönder</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopUpMail