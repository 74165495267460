import axios from 'axios';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { changeFormStep, getById, removePeriodDays, removePeriodTimes, setAdultCount, setName, setPeriodDays, setPeriodTimes } from '../../../../redux/AnalysisSlice';

const AnalysisInfo: FC = () => {
    //Validator
    let params = useParams();

    const analysisValues = useAppSelector(state => state.analysis)
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (params.id !== undefined) { 
                axios.get(`https://compare.hoteldoping.com/arg/api/analysis/view/${params.id}`)
                    .then(function (response) {
                        console.log("response",response)
                        if (response.data !== null) {
                            dispatch(getById(response.data));
                        }
                    }) 
        }
    }, [params,dispatch])
 

    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatch(changeFormStep(1));
    }
    const handlePeriodDays=(day:string,e:any)=>{
        if(e.target.checked){
            dispatch(setPeriodDays(day));
        }
        else dispatch(removePeriodDays(day))
    }
    const handlePeriodTimes=(time:string,e:any)=>{
        if(e.target.checked){
            dispatch(setPeriodTimes(time));
        }
        else dispatch(removePeriodTimes(time))
    }
    const handleDaysValues=(day:string):boolean=>{
        if(analysisValues.period_days!=null){
            return analysisValues.period_days.some((s:any)=>s==day);
        }
        return false
    }
    const handleTimesValues=(day:string):boolean=>{
        if(analysisValues.period_times){
            return analysisValues.period_times.some((s:any)=>s==day);
        }
        return false
    }
    return (<>
        <div className='px-8 py-4 border-b border-gray-200  place-items-center justify-between '>
            <h1 className='text-md mb-2 font-semibold text-gray-700 text-2xl'>
                Analiz bilgileri
            </h1>
            <p className='text-xs font-normal text-gray-400'>Analiz için isim ve diğer detayları belirleyin</p>
        </div>
        <div className='p-8 pb-0'>
            <form
                onSubmit={(e) => {
                    if (analysisValues.title !== "" && analysisValues.period_start !== "" && analysisValues.period_repeat !== undefined && analysisValues.period_repeat >= 0 && analysisValues.adultCount! >= 0) {
                        handleSubmit(e)
                    }
                }
                }
                className="bg-white border-gray-200 rounded">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="analysisName">Analiz Adı</label>
                    <input
                        required
                        className=" appearance-none border border-gray rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        value={analysisValues.title}
                        onChange={(e) => {
                            dispatch(setName(e.target.value));
                        }} />
                    <div className="mb-2"></div>
                </div>
                <div className='border-b py-3 font-semibold mb-5 text-gray-700'>Tarama zamanını planlayınız</div>

                <div className="mb-7">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">Günler</label>
                    <div className='grid grid-cols-7'>
                        <label><input onChange={(e)=>handlePeriodDays("monday",e)} checked={handleDaysValues("monday")} type="checkbox" /> Pzt</label>
                        <label><input onChange={(e)=>handlePeriodDays("tuesday",e)} checked={handleDaysValues("tuesday")} type="checkbox" /> Sal</label>
                        <label><input onChange={(e)=>handlePeriodDays("wednesday",e)} checked={handleDaysValues("wednesday")} type="checkbox" /> Çar</label>
                        <label><input onChange={(e)=>handlePeriodDays("thursday",e)} checked={handleDaysValues("thursday")} type="checkbox" /> Per</label>
                        <label><input onChange={(e)=>handlePeriodDays("friday",e)} checked={handleDaysValues("friday")} type="checkbox" /> Cum</label>
                        <label><input onChange={(e)=>handlePeriodDays("saturday",e)} checked={handleDaysValues("saturday")} type="checkbox" /> Cmt</label>
                        <label><input onChange={(e)=>handlePeriodDays("sunday",e)} checked={handleDaysValues("sunday")} type="checkbox" /> Paz</label>
                    </div> 
                </div>

                <div className='mb-7'>
                    <label className="block text-gray-700 text-sm font-semibold mb-2">Saatler</label>
                    <div className='grid grid-cols-6 gap-5'>
                        <label><input onChange={(e)=>handlePeriodTimes("00:00",e)} checked={handleTimesValues("00:00")} type="checkbox" /> 00:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("01:00",e)} checked={handleTimesValues("01:00")} type="checkbox" /> 01:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("02:00",e)} checked={handleTimesValues("02:00")} type="checkbox" /> 02:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("03:00",e)} checked={handleTimesValues("03:00")} type="checkbox" /> 03:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("04:00",e)} checked={handleTimesValues("04:00")} type="checkbox" /> 04:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("05:00",e)} checked={handleTimesValues("05:00")} type="checkbox" /> 05:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("06:00",e)} checked={handleTimesValues("06:00")} type="checkbox" /> 06:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("07:00",e)} checked={handleTimesValues("07:00")} type="checkbox" /> 07:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("08:00",e)} checked={handleTimesValues("08:00")} type="checkbox" /> 08:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("09:00",e)} checked={handleTimesValues("09:00")} type="checkbox" /> 09:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("10:00",e)} checked={handleTimesValues("10:00")} type="checkbox" /> 10:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("11:00",e)} checked={handleTimesValues("11:00")} type="checkbox" /> 11:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("12:00",e)} checked={handleTimesValues("12:00")} type="checkbox" /> 12:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("13:00",e)} checked={handleTimesValues("13:00")} type="checkbox" /> 13:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("14:00",e)} checked={handleTimesValues("14:00")} type="checkbox" /> 14:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("15:00",e)} checked={handleTimesValues("15:00")} type="checkbox" /> 15:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("16:00",e)} checked={handleTimesValues("16:00")} type="checkbox" /> 16:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("17:00",e)} checked={handleTimesValues("17:00")} type="checkbox" /> 17:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("18:00",e)} checked={handleTimesValues("18:00")} type="checkbox" /> 18:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("19:00",e)} checked={handleTimesValues("19:00")} type="checkbox" /> 19:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("20:00",e)} checked={handleTimesValues("20:00")} type="checkbox" /> 20:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("21:00",e)} checked={handleTimesValues("21:00")} type="checkbox" /> 21:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("22:00",e)} checked={handleTimesValues("22:00")} type="checkbox" /> 22:00</label>
                        <label><input onChange={(e)=>handlePeriodTimes("23:00",e)} checked={handleTimesValues("23:00")} type="checkbox" /> 23:00</label>
                    </div>
                </div>
                <div className='mb-4'>
                    <label className="block text-gray-700 text-sm font-semibold mb-2">
                        Yetişkin sayısı
                    </label>
                    <select
                        required
                        value={analysisValues.adultCount}
                        onChange={(e) => { dispatch(setAdultCount(e.target.value)) }}
                        className='shadow border border-gray rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'>
                        <option value="0" >Yetişkin sayısı seçiniz</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>

                </div>

            </form >
        </div >
    </>
    )
}

export default AnalysisInfo
