
import { FC, useEffect } from "react"
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loginModel } from "../../models/auth";
import { userLogin } from "../../redux/AuthSlice";

const Login: FC = () => {

    function handleSubmit(e: any) {

        e.preventDefault()
        const { email, password } = e.target.elements
        let params: loginModel = {
            email: email.value,
            password: password.value
        }
        dispatch(userLogin(params))
    }

    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.auth.isLoading)
    return (<div className="mt-8">

        <h3 className="my-5 text-center text-md  font-semibold text-gray-700">Lütfen hesap bilgileriniz ile giriş yapın</h3>
        <form onSubmit={handleSubmit}>
            <div>
                <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email</label>
                <input type="email" name="email" id="email" placeholder="Email" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
            </div>

            <div className="mt-6">
                <div className="flex justify-between mb-2">
                    <label className="text-sm text-gray-600 dark:text-gray-200">Şifre</label>
                    <Link to="/auth/forgot" className="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline">Şifremi Unuttum ?</Link>
                </div>

                <input type="password" name="password" id="password" placeholder="Şifre" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
            </div>

            <div className="mt-6">
                <button
                    className="w-full inline-flex items-center text-center place-content-center px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                    {isLoading && (<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>)}
                    Giriş Yap
                </button>
            </div>

        </form>

        <p className="mt-6 text-sm text-center text-gray-400">Henüz Hesabınız Yokmu ? &nbsp;
            <Link to="/auth/register" className="text-blue-500 focus:outline-none focus:underline hover:underline">Hesap Oluştur</Link></p>
    </div>)
}
export { Login }