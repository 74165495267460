import { Navigate, Route, Routes } from "react-router-dom";
import { AlertWrapper } from "../pages/Alert/AlertWrapper";
import WizardWrapper from "../pages/Analyse/Wizard/WizardWrapper";
import { AnalyseList } from "../pages/Analyse/AnalyseList";
import { PropertyWrapper } from "../pages/Property/PropertyWrapper";
import { TimedashWrapper } from "../pages/TimePlan/TimeDashWrapper";
import { FullLayout } from "../shared/FullLayout";
import { MasterLayout } from "../shared/MasterLayout";
import { FormWrapper } from "../pages/Inventory/FormWrapper";
import InventoryWrapper from "../pages/Inventory/InventoryWrapper";
import { AnalyseSummary } from "../pages/Analyse/AnalyseSummary";
import { AnalyseResult } from "../pages/Analyse/AnalyseResult";
import { TestComponent } from "../pages/Test/TestComponent";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/analyse/index" />} />

        {/* Pages */}
        <Route path="analyse">
          <Route path="index" element={<AnalyseList />} />
          <Route path="summary/:id" element={<AnalyseSummary />} />
          <Route path="result/:id/:scan_start/:compare_status" element={<AnalyseResult />} />
          <Route path="create" element={<WizardWrapper />} />
          <Route path="edit/:id" element={<WizardWrapper />} />    {/*(analiz düzenleme sayfası)*/}
        </Route>

        <Route path="inventory">
          <Route path="create" element={<FormWrapper />} />
          <Route path="edit/:id" element={<FormWrapper />} />
          <Route path="list" element={<InventoryWrapper />} />
        </Route>
        <Route path="alert" element={<AlertWrapper />} />

        {/* TODO: Kalkacak */}
        <Route path="*" element={<Navigate to="/auth/login" />} />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
      <Route element={<FullLayout />}>
        <Route path="timedash" element={<TimedashWrapper />} />
      </Route>
      <Route element={<FullLayout />}>
        <Route path="test" element={<TestComponent />} />
      </Route>
    </Routes>
  );
};
/*
const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}
*/
export { PrivateRoutes };
