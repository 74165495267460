/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { Login } from './Login'
import { Register } from './Register'

const AuthLayout = () => {
    const authState = useAppSelector((state) => state.auth)

    return (

        <div className="bg-white dark:bg-gray-900">
            <div className="flex justify-center h-screen">

                <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
                    <div className="flex-1">
                        <div className="flex flex-col place-items-center w-full">
                            <img
                                className="block w-auto" style={{ height: 100 }}
                                src="/assets/logo/kare100@3x.png" srcSet="/assets/logo/kare100@1x.png 1x, /assets/logo/kare100@2x.png 2x, /assets/logo/kare100@3x.png 3x"
                                alt="" />
                        </div>

                        {(authState.error &&
                            <div className="border p-3 my-5 mt-10 rounded-lg border-solid border-red-200 bg-red-100 shadow-sm">
                                {authState.errorMessage}
                            </div>)}

                        <Outlet />

                    </div >
                </div >
                <div className="hidden bg-cover lg:block lg:w-2/3" style={{ backgroundImage: "url('/assets/loginbg.jpg')" }}>
                    <div className="flex items-center place-content-center h-full px-20 bg-gray-900 bg-opacity-70">
                        <div>
                            <h2 className="text-4xl font-bold text-white">Hotel Doping Compare</h2>

                            <p className="max-w-xl mt-10 text-3xl text-gray-300">Kullanımı en kolay otel analiz yazılımı...</p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

const AuthWrapper = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route path='login' element={<Login />} />
            <Route path='register' element={<Register />} />
            <Route index element={<Login />} />
        </Route>
    </Routes>
)

export { AuthWrapper }

