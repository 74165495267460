import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { TimeState } from '../models/time';

const initialState = {
  isLoading: false,
  items: [],
  fakestate: 0,
} as TimeState


export const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    addItem: (state, { payload }) => {
      state.items.push(payload);
    },
    updateItem: (state, { payload }) => {
      const index = state.items.findIndex((r: any) => r.id === payload);
      const row = state.items[index];
      state.items[index].length = DateTime.fromISO(row.start).diffNow("seconds").seconds * -1;
    },
    removeItem: (state, { payload }) => {
      const index = state.items.findIndex((r: any) => r.id === payload);
      state.items.splice(index, 1);
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setFake: (state, { payload }) => {
      state.items.push({ start: "", text: "", length: 0, id: "" });
      state.items.pop();
    },
  },
})

// Action creators are generated for each case reducer function
export const { addItem, updateItem, removeItem, setLoading, setFake } = timeSlice.actions

export default timeSlice.reducer