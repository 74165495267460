import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpMail, PopUpModel } from "../models/popUp";


const initialState: PopUpModel = {
    removePopUp: {
        removedId: 0,
        status: false,
        ok: false,
        question: "Analizi silmek istediğinize emin misiniz?"
    },
    mailPopUp: {
        status: false,
        subject: "",
        replyTo: "",
        to: "",
        msg: "",
        isSending: false
    }
}

export const popUpSlice = createSlice({
    name: "popUp",
    initialState,
    reducers: {
        closeRemovePopUp: (state) => {
            state.removePopUp.status = false;
        },
        openRemovePopUp: (state, { payload }) => {
            state.removePopUp.removedId = payload;
            state.removePopUp.status = true;
        },
        setMailPopUp: (state, payload: PayloadAction<PopUpMail>) => {
            state.mailPopUp = payload.payload;
        },
        hideMailPopup: (state) => {
            state.mailPopUp.status = false;
        },
        setMailSpinner: (state, { payload }) => {
            state.mailPopUp.isSending = payload;
        }


    }
})

export default popUpSlice.reducer;
export const { closeRemovePopUp, openRemovePopUp, setMailPopUp, hideMailPopup, setMailSpinner } = popUpSlice.actions;