import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { App } from '../App'
import { AuthWrapper } from '../pages/auth/AuthWrapper'
import { useAppSelector } from '../hooks'
import { Logout } from '../pages/auth/Logout'
import { ErrorsPage } from '../pages/errors/ErrorsPage'

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {

  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>

          <Route path='error/*' element={<ErrorsPage />} />
          
          <Route path='logout' element={<Logout />} />

          {isLoggedIn ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
              
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthWrapper />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
