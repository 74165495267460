import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { changeFormStep } from '../../../redux/AnalysisSlice'

const WizardSidebar = () => {
    const dispatch = useAppDispatch();
    const step = useAppSelector((state) => state.analysis.formStep);
    //selected page


    return (
        <>
            <div className='bg-white px-6 py-4 border rounded-lg grid grid-row-1 divide-y divide-y-1 '>

                <div className='flex py-4'>
                    <div className="grid grid-col-4 gap-8">
                        <div className="flex gap-3 cursor-pointer hover:ml-2 transition-all" onClick={() => dispatch(changeFormStep(0))}>
                            <div>
                                <button className={step === 0 ? " ml-2  bg-blue-700 text-white border rounded-md px-5 py-2 font-semibold text-lg hover:bg-blue-500" : "bg-white text-blue-500 border rounded-md px-5 py-2 font-semibold text-lg hover:bg-blue-500 hover:text-white"}>1</button>
                            </div>
                            <div className='px-2 flex-none ml-1'>
                                <span className='text-gray-700 font-semibold'>Analiz Bilgileri</span>
                                <p className='text-xs text-gray-400'>Analiz adını ve periyodu belirleyin</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex  py-4'>
                    <div className="grid grid-col-4 gap-8">
                        <div className="flex gap-3 cursor-pointer hover:ml-2 transition-all" onClick={() => dispatch(changeFormStep(1))}>
                            <div>
                                <button className={step === 1 ? " ml-2 transition-all bg-blue-700 text-white border rounded-md px-5 py-2 font-semibold text-lg hover:bg-blue-500" : "bg-white text-blue-500 border rounded-md px-5 py-2 font-semibold text-lg hover:bg-blue-500 hover:text-white"}>2</button>
                            </div>
                            <div className='px-2 flex-none'>
                                <span className='text-gray-700 font-semibold'>Tarih Bilgileri</span>
                                <p className='text-xs text-gray-400'>Taranacak tarihleri belirleyin</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex  py-4'>
                    <div className="grid grid-col-4 gap-8">
                        <div className="flex gap-3 cursor-pointer hover:ml-2 transition-all" onClick={() => dispatch(changeFormStep(2))}>
                            <div>
                                <button className={step === 2 ? " ml-2 transition-all bg-blue-700 text-white border rounded-md px-5 py-2 font-semibold text-lg hover:bg-blue-500" : "bg-white text-blue-500 border rounded-md px-5 py-2 font-semibold text-lg hover:bg-blue-500 hover:text-white"} >3</button>
                            </div>
                            <div className='px-2  flex-none '>
                                <span className='text-gray-700 font-semibold'>Tesis Bilgileri</span>
                                <p className='text-xs text-gray-400'>Analiz edilecek tesisleri belirleyin</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default WizardSidebar
