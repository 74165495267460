import clsx from "clsx"
import { DateTime } from "luxon"
import { useState } from "react"
import { channelsconst } from "../../channelsconst"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { PopUpMail } from "../../models/popUp"
import { setMailPopUp } from "../../redux/PopUpSlice"
import { ResultItem } from "../../redux/ResultSlice"
import { MYSVG } from "../../shared/Helpers/MYSVG"
import { formattedPrice } from "../../shared/Helpers/NumberHelper"

function channelLink(prop: any) {
    let settings = prop.settings
    let dateObjStart = DateTime.fromFormat(prop.dateStart, "yyyy-MM-dd")
    let dateObjEnd = DateTime.fromFormat(prop.dateEnd, "yyyy-MM-dd")
    let hotelURL = settings[prop.channel] ? settings[prop.channel].hotelUrl : ""
    let adultCount = prop.adultCount
    if (prop.channel === "tatilsepeti.com") {
        let dateStart = dateObjStart.toFormat("dd.MM.yyyy")
        let dateEnd = dateObjEnd.toFormat("dd.MM.yyyy")
        return (
            "https://www.tatilsepeti.com/" +
            hotelURL +
            "?ara=oda:" +
            adultCount +
            ";tarih:" +
            dateStart +
            "," +
            dateEnd +
            ";click:true"
        )
    } else if (prop.channel === "otelz.com" || prop.channel === "m.otelz.com") {
        hotelURL = settings[prop.channel.replace("m.", "")] ? settings[prop.channel.replace("m.", "")].hotelUrl : ""
        let hotelID = settings[prop.channel.replace("m.", "")] ? settings[prop.channel.replace("m.", "")].id : ""
        let dateStart = dateObjStart.toFormat("yy-MM-dd")
        let dateEnd = dateObjEnd.toFormat("yy-MM-dd")
        let hash =
            '{"p":' +
            hotelID +
            ',"t":0,"g":6,"s":"' +
            dateStart +
            '","e":"' +
            dateEnd +
            '","c":"TR","d":0,"q":1,"z":0,"rt":0,"lt":"39.925533","ln":"32.866287","r":[{"a":' +
            adultCount +
            "}]}"
        return "https://www.otelz.com/hotel/" + hotelURL + "/room-prices/" + hotelID + "?search=" + btoa(hash)
    } else if (prop.channel === "odamax.com" || prop.channel === "m.odamax.com") {
        hotelURL = settings[prop.channel.replace("m.", "")] ? settings[prop.channel.replace("m.", "")].hotelUrl : ""
        let dateStart = dateObjStart.toFormat("dd.MM.yyyy")
        let dateEnd = dateObjEnd.toFormat("dd.MM.yyyy")
        return (
            "https://www.odamax.com/tr/hotel/" +
            hotelURL +
            "?check_in=" +
            dateStart +
            "&check_out=" +
            dateEnd +
            "&room=1&adult_1=" +
            adultCount +
            ""
        )
    } else if (prop.channel === "etstur.com") {
        let dateStart = dateObjStart.toFormat("dd.MM.yyyy")
        let dateEnd = dateObjEnd.toFormat("dd.MM.yyyy")
        return (
            "https://www.etstur.com/" +
            hotelURL +
            "?check_in=" +
            dateStart +
            "&check_out=" +
            dateEnd +
            "&room=1&adult_1=" +
            adultCount +
            ""
        )
    } else if (prop.channel === "hotels.com") {
        let dateStart = dateObjStart.toFormat("yyyy-MM-dd")
        let dateEnd = dateObjEnd.toFormat("yyyy-MM-dd")
        return (
            "https://tr.hotels.com/" +
            hotelURL +
            "?q-check-in=" +
            dateStart +
            "&q-check-out=" +
            dateEnd +
            "&q-room-0-adults=" +
            adultCount +
            ""
        )
    }else if (prop.channel === "m.hotels.com") {
        let dateStart = dateObjStart.toFormat("yyyy-MM-dd")
        let dateEnd = dateObjEnd.toFormat("yyyy-MM-dd")
        return (
            "https://tr.hotels.com/" +
            hotelURL +
            "?q-check-in=" +
            dateStart +
            "&q-check-out=" +
            dateEnd +
            "&q-room-0-adults=" +
            adultCount +
            ""
        )
    } else if (prop.channel === "jollytur.com") {
        let dateStart = dateObjStart.toFormat("yyyy.MM.dd")
        let dateEnd = dateObjEnd.toFormat("yyyy.MM.dd")
        return (
            "https://jollytur.com/" +
            hotelURL +
            "?StartDate=" +
            dateStart +
            "&EndDate=" +
            dateEnd +
            "&Rooms=" +
            adultCount +
            ""
        )
    } else if (prop.channel === "tatilbudur.com" || prop.channel === "m.tatilbudur.com") {
        let dateStart = dateObjStart.toFormat("yyyy.MM.dd")
        let dateEnd = dateObjEnd.toFormat("yyyy.MM.dd")
        return (
            "https://www.tatilbudur.com/" +
            hotelURL +
            "?StartDate=" +
            dateStart +
            "&EndDate=" +
            dateEnd +
            "&Rooms=" +
            adultCount +
            ""
        )
    } else if (prop.channel.substr(0,8) === "trivago.") { 
        let hotelID = settings["trivago.com"] ? settings["trivago.com"].id : ""
        let dateStart = dateObjStart.toFormat("yyyyMMdd")
        let dateEnd = dateObjEnd.toFormat("yyyyMMdd")
        return (
            "https://www.trivago.com.tr/tr/srl?search=100-"+hotelID+";dr-"+dateStart+"-"+dateEnd+";rc-1-"+adultCount
        )
    } else return "#"
}

function ChannelBox(prop: any) {
    return (
        <a
            target="_blank"
            rel="noreferrer"
            href={channelLink(prop)}
            className={clsx(
                "relative flex flex-col items-center rounded-sm border py-3 pb-2 font-semibold  text-slate-900 shadow-sm",
                prop.price > 0
                    ? prop.handicap
                        ? "border-amber-400 bg-amber-100"
                        : "border-gray-200 bg-white"
                    : "border-red-300 bg-red-100"
            )}
        >
            <div className={`text-sm  text-gray-500 ${prop.extraClass}`}>
                {prop.channel ? (
                    <img src={channelsconst[prop.channel].logo} className="max-h-3" alt="" />
                ) : (
                    prop.channel
                )}
            </div>
            <div className="sm:text-md mt-1 text-sm text-gray-600">
                {prop.price > 0 ? formattedPrice(prop.price.toFixed(0)) + " " + prop.currency : "Dolu"}
            </div>
            <div className="flex place-items-center text-xs text-gray-400">
                <MYSVG path="/assets/svg/searchmini.svg" className="" svgClassName="h-3 w-3" />
                {DateTime.fromISO(prop.updated_at).setLocale("tr-TR").toFormat("dd MMM HH:mm")}
            </div>
            {prop.handicap && (
                <div className="flex place-items-center text-xs text-gray-400" title={prop.handicap}>
                    <MYSVG
                        path="/assets/svg/warning.svg"
                        className="absolute -top-1 -right-1 rounded-lg border-t border-r border-amber-500 bg-amber-100 p-1 text-yellow-600"
                        svgClassName="h-4 w-4"
                    />
                </div>
            )}
            {prop.price < 0 && (
                <div className="flex place-items-center text-xs text-gray-400">
                    <MYSVG
                        path="/assets/svg/notavail.svg"
                        className="absolute -top-1 -right-1 rounded-lg border-t border-r border-red-400 bg-red-100 p-1 text-red-600"
                        svgClassName="h-4 w-4"
                    />
                </div>
            )}
        </a>
    )
}

interface propType {
    resultRow: ResultItem
}

function AnalyseResultRow(prop: propType) {
    const [show, setShow] = useState(false)
    const selectedDate = useAppSelector((state) => state.property.withPrice)
    const allShow = useAppSelector((state) => state.layout.allShow)
    const dispatch = useAppDispatch()
    var mailText = ""
    try {
        mailText =
            "Sayın " +
            prop.resultRow.PropertyInventory.title +
            " Yetkilisi;\n\n" +
            'Yapmış olduğumuz kontrolde Tatilbudur fiyatının, "' +
            (channelsconst[prop.resultRow.low_ch] ? channelsconst[prop.resultRow.low_ch].title : "") +
            '" acentesinden ' +
            prop.resultRow.tb_diff_by_lower?.toFixed(0) +
            " TL (%" +
            prop.resultRow.tb_rate_by_lower?.toFixed(1) +
            ") daha pahalı olduğu tespit edilmiştir.\n\n" +
            "Sahip olduğumuz yapay zeka teknolojisinin rutin kontrolleri neticesinde tespit edilen fiyat farklılıkları; diğer tesisler arasındaki sıralamalarınızı, reklam yatırımlarını ve acenteler arası rekabeti olumsuz yönde etkileyecektir.\n\n" +
            "Oluşan bu fiyat farkının çözümü için desteğinizi önemle rica ederiz.\n\n" +
            "Kontrol Parametreleri :\n" +
            "Giriş Tarihi : " +
            DateTime.fromISO(prop.resultRow.start_date).setLocale("tr-TR").toFormat("dd MMMM yyyy") +
            "\n" +
            "Çıkış Tarihi : " +
            DateTime.fromISO(prop.resultRow.end_date).setLocale("tr-TR").toFormat("dd MMMM yyyy") +
            "\n"

        mailText +=
            "Tespit Edilme Zamanı : " +
            DateTime.fromISO((prop.resultRow.found_dt_json as any)[prop.resultRow.low_ch])
                .setLocale("tr-TR")
                .toFormat("dd MMMM yyyy HH:mm") +
            ""
    } catch (ex) {}

    let mailSubject =
        "Tatilbudur ile " +
        (channelsconst[prop.resultRow.low_ch] ? channelsconst[prop.resultRow.low_ch].title : "") +
        " Fiyat Eşitsizliği Hakkında"

    return (
        prop.resultRow &&
        prop.resultRow.PropertyInventory && (
            <div
                className={`propertyMoreItemContainer  ${
                    show || allShow ? "my-3 mb-2 shadow-lg shadow-sky-100" : "-mb-1"
                }`}
            >
                <div
                    onClick={(e) => {
                        setShow(!show)
                    }}
                    className="flex flex-nowrap place-items-center p-2 px-4"
                >
                    <div className="flex flex-grow flex-row py-1">
                        <h1
                            className="sm:text-md flex flex-nowrap place-items-center text-xs font-semibold text-gray-700"
                            title={prop.resultRow.id.toString()}
                        >
                            <MYSVG path="/assets/svg/build.svg" className="mr-2" svgClassName="h-5 w-5" />[
                            {prop.resultRow.PropertyInventory.property_priority}]{" "}
                            {prop.resultRow.PropertyInventory.title}
                            {prop.resultRow.PropertyInventory.property_note && !show && (
                                <MYSVG
                                    path="/assets/svg/info.svg"
                                    svgClassName="h-4 w-4 ml-2"
                                    title={prop.resultRow.PropertyInventory.property_note}
                                />
                            )}
                        </h1>
                    </div>
                    <div className="flex place-items-center border-x border-gray-300 px-3 text-xs font-semibold">
                        {prop.resultRow.trivago_top_channel && (
                            <div className="flex gap-2 text-center">
                                <div>
                                    <img src="/assets/channelLogos/trivago_icon.png" className="h-4" alt="" />
                                    <span className="text-xs font-bold  text-sky-500">Top</span>
                                </div>
                                <div>
                                    <span className="font-bold text-gray-600">
                                        {prop.resultRow.trivago_top_channel}
                                    </span>
                                    <br />
                                    {prop.resultRow.trivago_top_price} TL
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex place-items-center border-r border-gray-300 px-3 text-xs font-semibold">
                        {prop.resultRow.trivago_min_channel && (
                            <div className="flex gap-2 text-center">
                                <div>
                                    <img src="/assets/channelLogos/trivago_icon.png" className="h-4" alt="" />
                                    <span className="text-xs font-bold  text-sky-500">Min</span>
                                </div>
                                <div>
                                    <span className="font-bold text-gray-600">
                                        {prop.resultRow.trivago_min_channel}
                                    </span>
                                    <br />
                                    {prop.resultRow.trivago_min_price} TL
                                </div>
                            </div>
                        )}
                    </div>

                    {prop.resultRow.tatilbudur_com == -1 || prop.resultRow.m_tatilbudur_com == -1 ? (
                        <MYSVG title="Tatilbudur Dolu" path="/assets/svg/tbd.svg" svgClassName="h-4 w-4 text-red-500" />
                    ) : (
                        ""
                    )}
                    {prop.resultRow.low_ch && (
                    <div className="flex flex-col place-items-center border bg-green-50 rounded-md ml-2 p-1 border-green-200 px-3 text-xs font-semibold"> 
                            {channelsconst[prop.resultRow.low_ch.replaceAll("_", ".")] ? (
                                <img
                                    src={channelsconst[prop.resultRow.low_ch.replaceAll("_", ".")].logo}
                                    className="mr-0 max-h-3 sm:mr-3"
                                    alt=""
                                />
                            ) : (
                                ""
                            )}
                            <div className="sm:text-md whitespace-nowrap text-xs font-semibold">
                                {prop.resultRow[
                                    prop.resultRow.low_ch.replaceAll(".", "_") as keyof typeof prop.resultRow
                                ]
                                    ? prop.resultRow[
                                          prop.resultRow.low_ch.replaceAll(".", "_") as keyof typeof prop.resultRow
                                      ] + " TL"
                                    : "-"}
                            </div>
                        </div> 
                    )}

                    {prop.resultRow.tb_rate_by_lower && (
                        <div className="ml-3 flex rounded-md bg-red-100 px-1 py-1">
                            <MYSVG
                                path="/assets/svg/arrowSmallUp.svg"
                                className=""
                                svgClassName="h-4 w-4 text-red-500"
                            />
                            <div className="ml-1 text-xs font-semibold text-red-500">
                                %{prop.resultRow.tb_rate_by_lower?.toFixed(1)}
                            </div>
                        </div>
                    )}
                </div>

                {(show || allShow) && (
                    <div className="-m-1 flex flex-col  gap-3 border bg-gray-50  p-3 shadow-inner transition-all ease-in-out">
                        <div>
                            {channelsconst && (
                                <div className="grid grid-cols-3 gap-3 md:grid-cols-6">
                                    {Object.entries(channelsconst).map(([key, value], index) =>
                                        (prop.resultRow as any)[key.replaceAll(".", "_")] != null ? (
                                            <ChannelBox
                                                key={prop.resultRow.id + "-" + key}
                                                show={show}
                                                price={(prop.resultRow as any)[key.replaceAll(".", "_")]}
                                                currency="TL"
                                                channel={key}
                                                updated_at={
                                                    (prop.resultRow.found_dt_json as any) !== null
                                                        ? (prop.resultRow.found_dt_json as any)[key]
                                                        : ""
                                                }
                                                handicap={
                                                    (prop.resultRow.handicap_json as any) !== null
                                                        ? (prop.resultRow.handicap_json as any)[key]
                                                        : ""
                                                }
                                                dateStart={prop.resultRow.start_date}
                                                dateEnd={prop.resultRow.end_date}
                                                adultCount={prop.resultRow.adult_count}
                                                settings={prop.resultRow.PropertyInventory.search_parameters}
                                                delay="500"
                                            />
                                        ) : (
                                            ""
                                        )
                                    )}
                                </div>
                            )}
                            {false && (
                                <>
                                    <div className="flex flex-col items-center rounded-sm border border-gray-200 bg-white p-1 text-sm font-semibold text-slate-900 shadow-sm sm:py-3">
                                        {selectedDate
                                            ? DateTime.fromFormat(selectedDate, "yyyy-MM-dd")
                                                  .setLocale("tr-TR")
                                                  .toFormat("dd MMMM yyyy")
                                            : ""}{" "}
                                        tarihi için fiyat bilgisi bulunamadı.
                                    </div>
                                </>
                            )}
                        </div>

                        {channelsconst[prop.resultRow.low_ch] &&
                            prop.resultRow.low_ch != "tatilbudur.com" &&
                            prop.resultRow.low_ch != "m.tatilbudur.com" && (
                                <>
                                    <hr />
                                    <div className="flex">
                                        <div
                                            className="flex place-items-center rounded-sm border border-sky-200 bg-sky-100 px-2 py-1 text-sm font-semibold text-sky-500 hover:bg-sky-500 hover:text-white"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                dispatch(
                                                    setMailPopUp({
                                                        to: "",
                                                        subject: mailSubject,
                                                        isSending: false,
                                                        msg: mailText,
                                                        replyTo: "parite@tatilbudur.com",
                                                        status: true,
                                                    } as PopUpMail)
                                                )
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="mr-2 h-4 w-4"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                                />
                                            </svg>{" "}
                                            Parite Maili Gönder
                                        </div>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={"/inventory/edit/" + prop.resultRow.inventory_id}
                                            className="ml-2 flex place-items-center rounded-sm border border-sky-200 bg-sky-100 px-2 py-1 text-sm font-semibold text-sky-500 hover:bg-sky-500 hover:text-white"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <MYSVG path="/assets/svg/info.svg" svgClassName="h-4 w-4 mr-2" /> Tesisi
                                            Düzenle
                                        </a>
                                    </div>
                                </>
                            )}
                        <hr />
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={"/inventory/edit/" + prop.resultRow.inventory_id}
                            className="flex text-sm"
                        >
                            {prop.resultRow.PropertyInventory.property_note && (
                                <>
                                    <MYSVG path="/assets/svg/info.svg" svgClassName="h-4 w-4 mr-2" />{" "}
                                    {prop.resultRow.PropertyInventory.property_note}
                                </>
                            )}
                            {!prop.resultRow.PropertyInventory.property_note && (
                                <>
                                    <MYSVG path="/assets/svg/info.svg" svgClassName="h-4 w-4 mr-2" /> Not yazmak için
                                    tıklayın
                                </>
                            )}
                        </a>
                    </div>
                )}
            </div>
        )
    )
}

export default AnalyseResultRow
