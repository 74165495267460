import { Outlet } from "react-router-dom"
import Nav from "./Header/Nav"

import '../index.css';
import 'react-loading-skeleton/dist/skeleton.css'
import Toast from "./Utils/Toast";
import Breadcrumb from "./Header/Breadcrumb";
import PopUpComponent from "./Utils/PopUpComponent";
import PopUpMail from "../pages/Property/PopUpMail";
import { useAppSelector } from "../hooks";

const MasterLayout = () => {
    const popUpMail = useAppSelector((state) => state.popUp.mailPopUp);

    return (
        <>
            <Nav />

            <div className="max-w-6xl mx-auto -mt-48">
                <Breadcrumb />
            </div>

            <div className='absolute right-10 top-20'><Toast /></div>

            <div className="max-w-6xl mx-auto mt-10  mb-10">
                <Outlet />
            </div>
            <div className="bg-gray-50 border-t shadow-lg  border-gray-200 w-full p-5 mt-5 font-semibold text-sm text-gray-600 text-center">Copyright © Hoteldoping 2022</div>

            <div>
                <PopUpComponent />
            </div>

            {popUpMail && popUpMail.status == true && (<>
                <div className="fixed top-0 w-screen h-screen border z-40 bg-black opacity-50"></div>
                <div className="absolute z-50"><PopUpMail /></div>
            </>)}

        </>
    )
}

export { MasterLayout }