import axios from "axios"
import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { fetchInventoriesWithPagination, InventoryQueryParams } from "../../redux/InventorySlice"
import { FormInput } from "./FormInput"

const FormWrapper: FC = () => {
    var inventoryInitialState = {
        "tatilbudur.com": { id: "", hotelUrl: "" },
        "otelz.com": { id: "", hotelUrl: "" },
        "odamax.com": { id: "", hotelUrl: "" },
        "etstur.com": { id: "", hotelUrl: "" },
        "tatilsepeti.com": { id: "", hotelUrl: "" },
        "jollytur.com": { id: "", hotelUrl: "" },
        "hotels.com": { id: "", hotelUrl: "" },
        "trivago.com": { id: "", hotelUrl: "" },
    }
    var initialHotelInfo = {
        property_priority: "",
        country: "",
        city: "",
        state: "",
        town: "",
        contract_type: "",
        concept_type: "",
        contract_person: "",
        property_note: "",
    }
    const dispatch = useAppDispatch()
    const inventories = useAppSelector((state) => state.inventory)
    const [chanelsUrlState, setChannelState] = useState<any>(inventoryInitialState)
    const [resultPropState, setresultPropState] = useState<any>({})
    const [hotelInfo, setHotelInfo] = useState<any>(initialHotelInfo)
    const [title, setTitle] = useState()
    const [searchKey, setSearchKey] = useState()
    const url = useParams()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false)

    const removeTrailingSlash = (str: string) => {
        return str.endsWith("/") ? str.slice(0, -1) : str
    }

    useEffect(() => {
        if (url.id !== undefined) {
            try {
                var params: InventoryQueryParams = {
                    filter: {
                        id: url.id,
                    },
                    paginator: undefined,
                    sorting: undefined,
                    searchTerm: undefined,
                }
                dispatch(fetchInventoriesWithPagination(params))
            } catch (error) {}
        }
    }, [])

    useEffect(() => {
        var inventory: any = inventories.data?.items.find((prop) => prop.id.toString() == url.id)

        if (inventory !== undefined) {
            setTitle(inventory.title)

            let info: any = {
                property_priority: inventory.property_priority,
                city: inventory.city,
                state: inventory.state,
                town: inventory.town,
                country: inventory.country,
                contract_person: inventory.contract_person,
                property_note: inventory.property_note,
            }
            setHotelInfo(info)
            setSearchKey(inventory.title)

            let existsValue = inventory.search_parameters
            let oldStates = chanelsUrlState
            Object.keys(existsValue).map((key: string) => (oldStates[key] = existsValue[key]))

            setChannelState(oldStates)
        }
    }, [inventories])
    const handleSubmit = () => {
        var activeChannels: any = []
        Object.keys(chanelsUrlState).map((key: string) => {
            if (chanelsUrlState[key].id !== "" || chanelsUrlState[key].hotelUrl) activeChannels.push(key)
        })

        let parameters = {
            title: title,
            property_priority: hotelInfo.property_priority,
            active_channels: activeChannels,
            search_parameters: chanelsUrlState,

            city: hotelInfo.city,
            state: hotelInfo.state,
            town: hotelInfo.town,
            country: hotelInfo.country,
            contract_type: hotelInfo.contract_type,
            contract_person: hotelInfo.contract_person,
            property_note: hotelInfo.property_note,
        }
        if (url.id !== undefined) {
            axios
                .post(`https://compare.hoteldoping.com/arg/api/propertyInventory/update/${url.id}`, parameters)
                .then((r: any) => {
                    navigate("/inventory/list")
                })
        } else {
            axios.post("https://compare.hoteldoping.com/arg/api/propertyInventory/store", parameters).then((r: any) => {
                navigate("/inventory/list")
            })
        }
    }

    const customIdSet = (channel: string, id: string) => {
        let parsedValue = {
            hotelUrl: "",
            id: id,
        }

        setChannelState({
            ...chanelsUrlState,
            [channel]: parsedValue,
        })
    }

    const parseUrl = (channel: string, url: string, fromInputBox: boolean) => {
        let parsedValue = {
            hotelUrl: "",
            id: "",
        }

        if (!fromInputBox) {
            var arr = url.split("|")
            parsedValue = {
                hotelUrl: arr[0],
                id: arr[1],
            }
        } else {
            let urlParsed: URL = new URL(url)
            if (url.includes(channel)) {
                var rawUrlPathName = urlParsed.pathname.substring(1, urlParsed.pathname.length)
                var rawUrlPathNameSlashTrim = removeTrailingSlash(rawUrlPathName)
                switch (channel) {
                    case "etstur.com":
                    case "tatilsepeti.com":
                    case "jollytur.com":
                    case "hotels.com":
                        const params = new URLSearchParams(url)  
                        parsedValue = {
                            hotelUrl: rawUrlPathNameSlashTrim.split("/")[0],
                            id: params.get("expediaPropertyId")?.toString() || "",
                        }
                        break

                    case "otelz.com":
                        if (rawUrlPathName.split("/").length >= 2)
                            parsedValue = {
                                hotelUrl: rawUrlPathName.split("/")[1],
                                id: rawUrlPathName.split("/")[2],
                            }
                        break

                    case "odamax.com":
                        if (rawUrlPathName.split("/").length >= 2)
                            parsedValue = {
                                hotelUrl: rawUrlPathName.split("/")[2],
                                id: "",
                            }
                        break

                    case "tatilbudur.com":
                        if (rawUrlPathName.split("/").length >= 2)
                            parsedValue = {
                                id: "",
                                hotelUrl: "",
                            }
                        break

                    case "trivago.com":
                        if (rawUrlPathName.split("/").length >= 2)
                            parsedValue = {
                                hotelUrl: rawUrlPathName.split("/")[2],
                                id: "",
                            }
                        break
                }
            }
        }
        setChannelState({
            ...chanelsUrlState,
            [channel]: parsedValue,
        })
        if (
            resultPropState["country_town_contractType"] !== undefined &&
            channel == "tatilbudur.com" &&
            parsedValue.id !== undefined
        ) {
            // eslint-disable-next-line eqeqeq
            let filtered = resultPropState["country_town_contractType"].find((r: any) => r.id == parsedValue.id)
            setHotelInfo(filtered)
        }
    }
    const handleFillAuto = () => {
        setLoading(true)
        axios
            .post("https://compare.hoteldoping.com/arg/api/hotelidsearch/search/", {
                search: searchKey,
            })
            .then((result: any) => {
                setresultPropState(result.data)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                alert("Veriler alınırken hata oluştu lütfen tekrar deneyiniz.")
            })
    }
    return (
        <>
            <div className="flex-grow rounded-lg border bg-white text-black ">
                <div className="place-items-center justify-between border-b border-gray-200  px-8 py-4 ">
                    <h1 className="text-md mb-2 text-2xl font-semibold text-gray-700">Tesis bilgileri</h1>
                </div>
                <div className="px-8">
                    <div className="flex gap-4 rounded border-b  border-gray-300 py-5">
                        <div className="">
                            <p className="mb-2 font-semibold text-gray-600">Önem Sırası</p>
                            <div className="flex items-start gap-4">
                                <input
                                    defaultValue={hotelInfo.property_priority}
                                    onChange={(e: any) => {
                                        setHotelInfo({
                                            ...hotelInfo,
                                            ["property_priority"]: e.target.value,
                                        })
                                    }}
                                    className="border-gray focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none "
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="flex-grow">
                            <p className="mb-2 font-semibold text-gray-600">Tesis Adı</p>
                            <div className="flex items-start gap-4">
                                <input
                                    defaultValue={title}
                                    onChange={(e: any) => {
                                        setTitle(e.target.value)
                                    }}
                                    className="border-gray focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none "
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex grid grid-cols-4 gap-4  rounded border-b border-gray-300 py-5">
                        <div>
                            <p className="mb-2 font-semibold text-gray-600">Ülke</p>
                            <input
                                defaultValue={hotelInfo.country}
                                onChange={(e: any) => {
                                    setHotelInfo({
                                        ...hotelInfo,
                                        ["country"]: e.target.value,
                                    })
                                }}
                                className="border-gray focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none "
                                type="text"
                            />
                        </div>
                        <div>
                            <p className="mb-2 font-semibold text-gray-600">Şehir</p>
                            <input
                                defaultValue={hotelInfo.city}
                                onChange={(e: any) => {
                                    setHotelInfo({
                                        ...hotelInfo,
                                        ["city"]: e.target.value,
                                    })
                                }}
                                className="border-gray focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none "
                                type="text"
                            />
                        </div>
                        <div>
                            <p className="mb-2 font-semibold text-gray-600">İlçe</p>
                            <input
                                defaultValue={hotelInfo.state}
                                onChange={(e: any) => {
                                    setHotelInfo({
                                        ...hotelInfo,
                                        ["state"]: e.target.value,
                                    })
                                }}
                                className="border-gray focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none "
                                type="text"
                            />
                        </div>
                        <div>
                            <p className="mb-2 font-semibold text-gray-600">Bölge</p>
                            <input
                                defaultValue={hotelInfo.town}
                                onChange={(e: any) => {
                                    setHotelInfo({
                                        ...hotelInfo,
                                        ["town"]: e.target.value,
                                    })
                                }}
                                className="border-gray focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none "
                                type="text"
                            />
                        </div>
                    </div>

                    <div className="flex grid grid-cols-4  gap-4 rounded border-gray-300 py-5">
                        <div>
                            <p className="mb-2 font-semibold text-gray-600">Kontratçı</p>
                            <input
                                defaultValue={hotelInfo.contract_person}
                                onChange={(e: any) => {
                                    setHotelInfo({
                                        ...hotelInfo,
                                        ["contract_person"]: e.target.value,
                                    })
                                }}
                                className="border-gray focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none "
                                type="text"
                            />
                        </div>

                        <div>
                            <p className="mb-2 font-semibold text-gray-600">Kontrat Tipi</p>
                            <input
                                defaultValue={hotelInfo.contract_type}
                                onChange={(e: any) => {
                                    setHotelInfo({
                                        ...hotelInfo,
                                        ["contract_type"]: e.target.value,
                                    })
                                }}
                                className="border-gray focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none "
                                type="text"
                            />
                        </div>
                        <div>
                            <p className="mb-2 font-semibold text-gray-600">Konsept</p>
                            <input
                                defaultValue={hotelInfo.concept_type}
                                onChange={(e: any) => {
                                    setHotelInfo({
                                        ...hotelInfo,
                                        ["property_concept"]: e.target.value,
                                    })
                                }}
                                className="border-gray focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none "
                                type="text"
                            />
                        </div>
                    </div>

                    <div className="flex grid grid-cols-1  gap-4 rounded border-gray-300 py-5">
                        <div>
                            <p className="mb-2 font-semibold text-gray-600">Tesis Notu</p>
                            <input
                                defaultValue={hotelInfo.property_note}
                                onChange={(e: any) => {
                                    setHotelInfo({
                                        ...hotelInfo,
                                        ["property_note"]: e.target.value || "",
                                    })
                                }}
                                className="border-gray focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none "
                                type="text"
                            />
                        </div>
                    </div>

                    <div className="-mx-8 place-items-center justify-between border-b border-gray-200  py-4 px-8 ">
                        <h1 className="text-md mb-2 text-2xl font-semibold text-gray-700">Mapping</h1>
                    </div>
                    <div className="my-5 flex gap-4 rounded border-b  border-gray-300 py-4">
                        <input
                            defaultValue={title}
                            onChange={(e: any) => {
                                setSearchKey(e.target.value)
                            }}
                            className="border-gray focus:shadow-outline w-96 appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none "
                            type="text"
                        />
                        <button
                            onClick={() => handleFillAuto()}
                            className="h-10 whitespace-nowrap rounded-md border bg-blue-700 px-3 text-white hover:bg-blue-800"
                        >
                            Otomatik Doldur
                        </button>

                        <p className="text-sm text-gray-600">
                            Bazı acentaların seçimlerini yaptıktan sonra arama metnini değiştirerek bir kaç kez arama
                            yaparak daha iyi sonuçlar alabilirsiniz.
                        </p>
                    </div>
                    <div className="flex flex-col">
                        {chanelsUrlState &&
                            Object.keys(chanelsUrlState)
                                .filter((r: any) => {
                                    return r !== "country_town_contractType"
                                })
                                .map((channel, index) => (
                                    <FormInput
                                        key={index}
                                        resultData={resultPropState[channel]}
                                        channelName={channel}
                                        channelLogo={"/assets/channelLogos/" + channel.replace(".com", "") + ".png"}
                                        parseMethod={(url: any, fromInputBox: boolean) =>
                                            parseUrl(channel, url, fromInputBox)
                                        }
                                        idSetMethod={(val: any) => customIdSet(channel, val)}
                                        hotelId={chanelsUrlState[channel].id}
                                        hotelUrl={chanelsUrlState[channel].hotelUrl}
                                        showId={true}
                                        showUrl={true}
                                        isLoading={isLoading}
                                    />
                                ))}
                    </div>
                    <button
                        onClick={() => handleSubmit()}
                        className="my-5 rounded bg-blue-600 p-3 px-5 text-white  hover:bg-blue-700"
                    >
                        Kaydet
                    </button>
                </div>
            </div>
        </>
    )
}

export { FormWrapper }
