import clsx from "clsx"
import { DateTime, Duration } from "luxon"
import { FC, useEffect } from "react"
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { TimeItem } from "../../models/time"
import { fetchProperties } from "../../redux/PropertySlice"
import { removeItem, setFake, updateItem } from "../../redux/TimeSlice"
import { Newitem } from "./NewItem"
import './timeline.scss'

const collect = require('collect.js');

const TimedashWrapper: FC = () => {
    const timeEvents = useAppSelector((state) => state.time.items)
    var fakeState = useAppSelector((state) => state.time.fakestate)
    const dispatch = useAppDispatch()

    const getSorted = () => {
        return collect(timeEvents).sortByDesc('start');
    }

    const getSummary = () => {
        const arr = collect(timeEvents).filter((r: any) => (r != null)).groupBy('text').map((item: any, key: any) => {

            return item.sum('length');
        }).map((sec: number, key: any) => {
            let arr: any = {}
            arr["text"] = key;
            arr["value"] = DateTime.fromSeconds(sec).setZone('UTC+0').toFormat("HH 'sa' mm 'dk' ss 'sn'");
            return arr
        }).values().sortByDesc('value');


        return arr;
    }


    const handleStop = (index: any) => {
        dispatch(updateItem(index))
    }

    const handleOldTaskClick = (row: TimeItem) => {
        const el = (document.querySelector("#taskInput") as HTMLInputElement);
        el.value = row.text;
        el.focus()
    }

    useEffect(() => {
        const tmr = setInterval(() => {
            dispatch(setFake(fakeState++));
        }, 1000)
        return () => clearInterval(tmr)
    }, [dispatch, fakeState])

    return (
        <>
            <div className="w-full flex">
                <div className="flex-grow">

                    <h3 className="text-5xl font-bold text-gray-500 mb-14">Jobs</h3>

                    <Newitem></Newitem>

                    {timeEvents && getSorted().map((row: TimeItem) => row !== null && (

                        <div onClick={() => { handleOldTaskClick(row) }} key={row.id} className={clsx("flex cursor-pointer rounded text-3xl mb-5", (row.length > 0 ? "border-gray-300 bg-gray-50" : "border border-green-300 bg-green-50 py-5"))}>

                            <div className={clsx("flex items-center text-gray-600 p-4 ")}>
                                <span className="">{DateTime.fromISO(row.start).toFormat('HH:mm')}</span>
                            </div>

                            <span className={clsx("border-l-4 font-semibold p-4 flex-grow", (row.length > 0 ? "text-gray-600" : "text-green-600"))}>
                                {row.text}
                            </span>

                            <div className={clsx("p-3 font-semibold ", (row.length > 0 ? "text-gray-600" : "text-green-600"))}>
                                {row.length > 0 ?
                                    DateTime.fromISO(row.start).toRelative({ base: DateTime.fromISO(row.start).plus({ seconds: row.length }) })?.replace("önce", "") :
                                    DateTime.fromISO(row.start).toRelative()?.replace("önce", "")}
                            </div>

                            {row.length > 0 ? '' : (
                                <button onClick={() => handleStop(row.id)} className="p-3 text-red-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            )}

                            {(<button onClick={() => dispatch(removeItem(row.id))} className="p-3 text-red-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </button>
                            )}
                        </div>
                    )
                    )}
                </div>
                <div className="ml-20">

                    <h3 className="text-5xl font-bold text-gray-500 mb-14">Top List</h3>

                    {getSummary().items.map((row: any) => (
                        <div onClick={() => { handleOldTaskClick(row) }} className="flex cursor-pointer flex-col border px-10 py-5 gap-2 mb-3 rounded-md border-gray-300 bg-white text-3xl shadow-md">
                            <div className="font-semibold text-blue-500">{row.text}</div>
                            <div className="flex-grow mx-5"></div>
                            <div>{row.value}</div>
                        </div>
                    )
                    )}
                </div>
            </div>
        </>
    )
}

export { TimedashWrapper }

