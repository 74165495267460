import { DateTime } from "luxon";
import { FC } from "react";
import { useAppDispatch } from '../../hooks';
import { TimeItem } from "../../models/time";
import { addItem } from "../../redux/TimeSlice";


const Newitem: FC = () => {
    const dispatch = useAppDispatch()
    const handleAdd = (event: any) => {

        if (event.key === 'Enter') {
            event.preventDefault();

            handleSubmit(event);
        }
    }
    const randomString = (strLength: number, charSet: string | undefined = undefined) => {
        var result = [];

        strLength = strLength || 5;
        charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        while (strLength--) { // (note, fixed typo)
            result.push(charSet.charAt(Math.floor(Math.random() * charSet.length)));
        }

        return result.join('');
    }
    const handleSubmit = (e: any) => {
        let text = e.target.value;

        let timeItem: TimeItem = {
            start: DateTime.now().toJSON(),
            text: text,
            length: 0,
            id: randomString(20),
        }

        if (text) {
            dispatch(addItem(timeItem));
            e.target.value = '';
        }
    }

    return (
        <>
            <div className="relative text-gray-600 focus-within:text-gray-400 mb-5 w-full text-3xl">
                <span className="absolute inset-y-0 left-0 flex items-center pl-4">
                    <i className="p-1 focus:outline-none focus:shadow-outline">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                        </svg>
                    </i>
                </span>
                <input id="taskInput" autoComplete="off" autoFocus onKeyDown={handleAdd} type="text" placeholder="What are you doing..." className="block w-full px-20 py-6 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" name="whatareyoudoing" />
            </div>
        </>
    )
}

export { Newitem };

