import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useAppSelector } from "../../hooks"
import { MYSVG } from "../Helpers/MYSVG"

function Breadcrumb() {

    const urlList: any = []
    urlList.push({ "uri": "/analyse", "title": "Analizler" })
    urlList.push({ "uri": "/analyse/create", "title": "Analiz Oluştur" })
    urlList.push({ "uri": "/analyse/result", "title": "Analiz Sonuçları" })
    urlList.push({ "uri": "/properties", "title": "Analiz Detayları" })
    urlList.push({ "uri": "/inventory/list", "title": "Tesis Listesi" })
    urlList.push({ "uri": "/inventory/create", "title": "Tesis Ekle" })
    urlList.push({ "uri": "/inventory/edit/", "title": "Tesis Düzenle" })

    const [title, setTitle] = useState("")

    const selected_analyse = useAppSelector((state) => state.analysis.selected_analyse)

    const url = useLocation()
    useEffect(() => {

        var title = "";
        const filtered = urlList.filter((r: any) => url.pathname.startsWith(r.uri))
        if (filtered.at(-1) !== undefined) {
            title = filtered !== undefined ? filtered.at(-1).title : ''
            setTitle(title)
        }
    })



    return (
        <>
            <div className="flex flex-col px-10 md:px-0">
                <h2 className="text-2xl text-white font-semibold my-2 mr-5 py-5">{title}</h2>
                <div className="flex items-center font-weight-bold">
                    <a href="/" className="text-white text-hover-white opacity-75 hover-opacity-100">Anasayfa</a>
                    <div className="mx-3"><MYSVG path="/assets/svg/arrowRight.svg" className="text-white opacity-75 " svgClassName="h-5 w-5 " /></div>
                    <a href="#" className="text-white text-hover-white opacity-75 hover-opacity-100">{title}</a>
                </div>
            </div>
        </>
    )
}
export default Breadcrumb