import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { Dates } from '../../../../models/analysis';
import { addMoreDateItem, removeDateItem, setToday, setTomorrow, setWeekend, updateDateItemEndDate, updateDateItemStartDate, updateDateItemTitle } from '../../../../redux/AnalysisSlice';
import { MYSVG } from '../../../../shared/Helpers/MYSVG';

const DateSelector: FC = () => {
    const dispatch = useAppDispatch();
    const analysisValues = useAppSelector(state => state.analysis)

    const handleChangeCheckboxInput = (e: any, checkBoxIndex: number) => {
        if (checkBoxIndex === 0) {
            dispatch(setToday(e.target.checked))
        }
        else if (checkBoxIndex === 1) {
            dispatch(setTomorrow(e.target.checked))

        }
        else if (checkBoxIndex === 2) {
            dispatch(setWeekend(e.target.checked))
        }
    }
    return (<>
        <div className='px-8 py-4 border-b border-gray-200  place-items-center justify-between '>
            <h1 className='text-md mb-2 font-semibold text-gray-700 text-2xl'>
                Tarih Bilgileri
            </h1>
            <p className='text-xs font-normal text-gray-400'>Otomatik ilerleyen tarihleri kullanabilir ya da spesifik tarih aralığı belirleyebilirsiniz.</p>
        </div>
        <div className='p-8 pb-0' >
            <div className='flex'>
                <div className='flex flex-row gap-10 mb-5'>
                    <div className='flex gap-4'>
                        <label className='flex'>
                            <input className='w-4 h-4' type="checkbox" onChange={(e) => handleChangeCheckboxInput(e, 0)} checked={analysisValues.date_today == 1 ? true : false} />
                            <p className='ml-4 text-gray-700 text-sm font-semibold'>Bugün</p>
                        </label>
                    </div>

                    <div className='flex gap-4'>
                        <label className='flex'>
                            <input className='w-4 h-4' type="checkbox" onChange={(e) => handleChangeCheckboxInput(e, 1)} checked={analysisValues.date_tomorrow == 1 ? true : false} />
                            <p className='ml-4 text-gray-700 text-sm font-semibold'>Yarın</p>
                        </label>
                    </div>

                    <div className='flex gap-4'>
                        <label className='flex'>
                            <input className='w-4 h-4' type="checkbox" onChange={(e) => handleChangeCheckboxInput(e, 2)} checked={analysisValues.date_weekend == 1 ? true : false} />

                            <p className='ml-4 text-gray-700 text-sm font-semibold'>Haftasonu</p>
                        </label>
                    </div>

                </div>
            </div>

            {analysisValues.date_others.map((dateRow: Dates, index) => (
                <div className='border-b border-b- py-4 rounded  ' key={index}>
                    <div className='flex flex-row gap-3 place-content-between items-center'>
                        <div className='flex flex-col flex-nowrap gap-2'>
                            <p className='font-semibold text-gray-700 text-sm'>Başlık</p>
                            <input
                                required
                                className=" border border-gray rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                value={dateRow.title}
                                onChange={(e) => dispatch(updateDateItemTitle({ indexInfo: index, dateInfo: e.target.value }))}
                            />
                        </div>
                        <div className='flex flex-col flex-nowrap gap-2'>
                            <p className='font-semibold text-gray-700 text-sm'>Giriş Tarihi</p>
                            <input
                                required
                                className=" border border-gray rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => dispatch(updateDateItemStartDate({ indexInfo: index, dateInfo: e.target.value }))}
                                type="date" value={dateRow.startDate}
                                max={dateRow.endDate} />
                        </div>
                        <div className='flex flex-col flex-nowrap gap-2'>
                            <p className='font-semibold text-gray-700 text-sm'>Çıkış Tarihi</p>
                            <div className='flex flex-row gap-2'>
                                <input
                                    required
                                    className="  border border-gray rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                                    onChange={(e) => dispatch(updateDateItemEndDate({ indexInfo: index, dateInfo: e.target.value }))}
                                    type="date" value={dateRow.endDate}
                                    min={dateRow.startDate} />
                                <button onClick={() => { dispatch(removeDateItem(index)) }} className=' text-red-500  rounded'>
                                    <div>
                                        <MYSVG
                                            path="/assets/svg/trash.svg"
                                            className=""
                                            svgClassName="h-5 w-5"
                                        />
                                    </div>
                                </button>
                            </div>
                        </div>



                    </div>
                </div>
            ))
            }
            <button type="button" className="mt-5 mb-10 flex place-items-center border gap-2 text-white bg-blue-700 rounded-md font-semibold px-4 py-2  hover:text-white hover:bg-blue-700"
                onClick={() => dispatch(addMoreDateItem())}>
                <MYSVG
                    path="/assets/svg/plus.svg"
                    className=""
                    svgClassName="h-5 w-5"
                /> Özel Tarih Ekle
            </button>
        </div>
    </>
    )
}

export default DateSelector


