import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    type: "success",
    message: "",
    hidden: true,
}
export const toastSlice = createSlice({
    name: "analysis",
    initialState,
    reducers: {
        resetToast: (state) => {
            state.hidden = true;
            state.type = "";
        },
        showToast: (state, { payload }) => {
            state.type = payload.status;
            state.message = payload.message;
            state.hidden = false;
        }
    },
})


export default toastSlice.reducer;
export const { resetToast, showToast } = toastSlice.actions;