import axios from "axios"
import { DateTime } from "luxon"
import { FC, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { Analysis } from "../../models/analysis"
import { fetchAnalysis, reset, setSelectedAnalyse } from "../../redux/AnalysisSlice"
import { openRemovePopUp } from "../../redux/PopUpSlice"
import { MYSVG } from "../../shared/Helpers/MYSVG"
import clsx from "clsx"
import { channelsconst } from "../../channelsconst"

const AnalyseList: FC = () => {
    const hourlyLimit = 12500
    const hourlyLimitMore = 20500
    const dailyLimit = 300000

    const [queueStats, setQueueStats] = useState([])
    const fetchQueueCount = () => {
        axios
            .post("https://compare.hoteldoping.com/arg/api/analysis/queueStatus")
            .then((r: any) => setQueueStats(r.data))
    }
    useEffect(() => {
        fetchQueueCount()
        const inter = setInterval(() => {
            fetchQueueCount()
        }, 5000)
        return () => clearInterval(inter)
    }, [])

    const analysis_list = useAppSelector((state) => state.analysis.items)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const calcRequest = (x: any, includeDayHour = false) => {
        return (
            (x.date_today + x.date_tomorrow + x.date_weekend + x.date_others.length) *
            x.property_ids.length *
            11 *
            (includeDayHour && x.period_days && x.period_days.length > 0 ? x.period_days.length : 1) *
            (includeDayHour && x.period_times && x.period_times.length > 0 ? x.period_times.length : 1)
        )
    }

    const requestFormatted = (count: number) => {
        if (count > hourlyLimitMore) {
            return <span className="font-bold text-red-600">{count.toLocaleString("tr-TR")}</span>
        } else if (count > hourlyLimit) {
            return <span className="font-semibold text-red-600">{count.toLocaleString("tr-TR")}</span>
        } else return <span className="font-semibold text-green-600">{count.toLocaleString("tr-TR")}</span>
    }
    const requestFormattedDay = (count: number) => {
        if (count > dailyLimit) {
            return <span className="font-bold text-red-600">{count.toLocaleString("tr-TR")}</span>
        } else return <span className="font-semibold text-green-600">{count.toLocaleString("tr-TR")}</span>
    }

    const calculateSpecialRequest = (day: string, hour: string) => {
        const result =
            analysis_list &&
            analysis_list
                .filter((analyse) => (analyse.period_days ? analyse.period_days.some((s: any) => s == day) : false))
                .filter((analyse) => (analyse.period_times ? analyse.period_times.some((h: any) => h == hour) : false))
                .reduce((partialSum: number, r: any) => partialSum + calcRequest(r), 0)

        return result > 0 ? requestFormatted(result) : ""
    }

    const calculateDayRequest = (day: string) => {
        const result =
            analysis_list &&
            analysis_list
                .filter((analyse) => (analyse.period_days ? analyse.period_days.some((s: any) => s == day) : false))
                .reduce(
                    (partialSum: number, r: any) =>
                        partialSum + calcRequest(r) * (r.period_times ? r.period_times.length : 1),
                    0
                )

        return result > 0 ? requestFormattedDay(result) : ""
    }

    useEffect(() => {
        dispatch(fetchAnalysis())
    }, [dispatch])

    const handleDelete = (id: number) => {
        dispatch(openRemovePopUp(id))
    }
    const handleAnalyseSelect = (analyse: Analysis) => {
        navigate("/analyse/summary/" + analyse.id)
    }

    const showDates = (analyse: Analysis) => {
        var errorCount = 0
        var datesArr = []

        if (analyse.date_today === 1) {
            datesArr.push("Bugün")
        }
        if (analyse.date_tomorrow === 1) {
            datesArr.push("Yarın")
        }
        if (analyse.date_weekend === 1) {
            datesArr.push("H.sonu")
        }

        analyse.date_others.length != 0 && datesArr.push("+" + analyse.date_others.length)
        for (var d of analyse.date_others) {
            let dateObj = DateTime.fromFormat(d.startDate, "yyyy-MM-dd")
            if (dateObj < DateTime.now()) errorCount++
        }

        return (
            <div
                title={analyse.date_others.map((r) => r.startDate + " / " + r.endDate).join("\n")}
                className="grid grid-cols-5 gap-2 divide-x text-center"
            >
                {datesArr.map((date, index) => (
                    <div key={index} className="py-1 text-center">
                        {date}
                    </div>
                ))}
                {errorCount > 0 && <span className="py-1 text-center text-red-400">-{errorCount}</span>}
            </div>
        )
    }

    return (
        <>
            <div className="mb-5 grid grid-cols-6 gap-3">
                {queueStats &&
                    queueStats.map((r: any) => (
                        <div
                            key={r.name}
                            className={clsx(
                                "relative flex flex-col items-center rounded-md border bg-white p-5 py-3  pb-2 font-semibold text-slate-900 shadow-sm"
                            )}
                        >
                            <div className={`text-sm  text-gray-500`}>
                                {channelsconst[
                                    r.name.replace("micro_", "").replace("_", ".").replace("_", ".").replace("_", ".")
                                ] && (
                                    <img
                                        src={
                                            channelsconst[
                                                r.name
                                                    .replace("micro_", "")
                                                    .replace("_", ".")
                                                    .replace("_", ".")
                                                    .replace("_", ".")
                                            ].logo
                                        }
                                        className="max-h-3"
                                        alt=""
                                    />
                                )}
                            </div>
                            <div className="sm:text-md mt-1 text-sm text-gray-600">{r.messages_ready > 0 ? r.messages_ready : <MYSVG path="/assets/svg/finish.svg" 
                                                        svgClassName="h-6 w-6 text-green-600" />}</div>
                        </div>
                    ))}
            </div>

            <div className="grid-col-1 mx-5 grid gap-5 md:mx-0 md:grid-cols-3">
                {analysis_list &&
                    analysis_list.map((analyse: Analysis) => (
                        <div key={analyse.id}>
                            <div className="flex rounded-md border border-gray-300 bg-white p-5  text-black shadow-md transition-shadow ease-in-out hover:shadow-lg">
                                <div className="flex w-full flex-wrap">
                                    <h1 className="text-md mb-1 flex w-full flex-nowrap place-items-center justify-between border-b border-gray-200 pb-3 font-semibold text-gray-600">
                                        <div className="flex flex-nowrap place-items-center ">
                                            {analyse?.analysis?.messages_ready &&
                                            analyse?.analysis?.messages_ready > 0 ? (
                                                <div className="relative flex flex-col place-content-center  place-items-center">
                                                    <span className="absolute -left-1 -bottom-3 w-6 text-center text-xs text-gray-400">
                                                        {analyse?.analysis?.messages_ready}
                                                    </span>
                                                    <MYSVG
                                                        path="/assets/svg/loading.svg"
                                                        svgClassName="h-6 w-6 animate-spin"
                                                    />
                                                    <svg
                                                        className="-ml-1 mr-3 h-6 w-6 animate-spin text-blue-400"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            stroke-width="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            ) : (
                                                <MYSVG
                                                    path="/assets/svg/search.svg"
                                                    className="mr-2"
                                                    svgClassName="h-6 w-6"
                                                />
                                            )}
                                            <span>{analyse.title} </span>
                                        </div>
                                        <div className="flex gap-2">
                                            <Link onClick={() => dispatch(reset())} to={`/analyse/edit/${analyse.id}`}>
                                                <div className="flex cursor-pointer text-sm text-gray-400">
                                                    <MYSVG path="/assets/svg/edit.svg" svgClassName="h-6 w-6" />{" "}
                                                </div>
                                            </Link>
                                            <Link onClick={(id) => handleDelete(analyse.id)} to="">
                                                <div className="flex cursor-pointer text-sm text-gray-400">
                                                    <MYSVG path="/assets/svg/trash.svg" svgClassName="h-6 w-6" />{" "}
                                                </div>
                                            </Link>
                                        </div>
                                    </h1>

                                    <div className="my-3 grid w-full grid-cols-1 gap-1 text-sm font-semibold text-gray-400">
                                        <div className="border-gray-150 flex items-center rounded-md border-b p-1 px-2  text-gray-600">
                                            <MYSVG
                                                path="/assets/svg/calendar.svg"
                                                className="mr-2"
                                                svgClassName="h-5 w-5"
                                            />{" "}
                                            {showDates(analyse)}
                                        </div>

                                        <div className="border-gray-150 flex items-center rounded-md border-b p-1 px-2  text-gray-600">
                                            <MYSVG
                                                path="/assets/svg/reload.svg"
                                                className="mr-2"
                                                svgClassName="h-5 w-5"
                                            />{" "}
                                            <span
                                                title={
                                                    (analyse.period_days && analyse.period_days.join(",")) +
                                                    "\n" +
                                                    (analyse.period_times && analyse.period_times.join(","))
                                                }
                                            >
                                                {analyse.period_days?.length || 0} Gün x &nbsp;
                                                {analyse.period_times?.length || 0} Saat
                                            </span>
                                        </div>

                                        <div className="border-gray-150 flex items-center rounded-md  border-b p-1 px-2  text-gray-600">
                                            <MYSVG
                                                path="/assets/svg/build.svg"
                                                className="mr-2"
                                                svgClassName="h-5 w-5"
                                            />{" "}
                                            <span>{analyse.property_ids?.length} Tesis</span>
                                        </div>
                                        <div className="border-gray-150 flex items-center rounded-md  p-1 px-2  text-gray-600">
                                            <MYSVG
                                                path="/assets/svg/crawl.svg"
                                                className="mr-2"
                                                svgClassName="h-5 w-5"
                                            />{" "}
                                            <span>~ {calcRequest(analyse)} Request / Hour</span>
                                        </div>
                                    </div>

                                    <a
                                        onClick={() => handleAnalyseSelect(analyse)}
                                        className="flex w-full cursor-pointer flex-nowrap place-content-center place-items-center gap-1 rounded-md bg-sky-100 py-2 px-2 font-semibold text-sky-500  hover:bg-sky-500 hover:text-white hover:shadow-md"
                                    >
                                        <MYSVG path="/assets/svg/search2.svg" className="mr-2" svgClassName="h-5 w-5" />
                                        <div>Analiz Sonuçları</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                <div className="flex cursor-pointer rounded-md border border-gray-300 bg-white p-5 text-black shadow-md transition-shadow ease-in-out hover:shadow-lg">
                    <div className="flex w-full place-content-center items-center">
                        <Link onClick={() => dispatch(reset())} to={"/analyse/create"}>
                            <MYSVG
                                path="/assets/svg/plus.svg"
                                className="mr-2 text-gray-300"
                                svgClassName="h-48 w-48"
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <table className="my-5 w-full border bg-white text-center">
                <thead className="border-b">
                    <tr>
                        <th className=" p-3" style={{ width: "12.5%" }}>
                            Saat
                        </th>
                        <th style={{ width: "12.5%" }}>Pzt</th>
                        <th style={{ width: "12.5%" }}>Sal</th>
                        <th style={{ width: "12.5%" }}>Çar</th>
                        <th style={{ width: "12.5%" }}>Per</th>
                        <th style={{ width: "12.5%" }}>Cum</th>
                        <th style={{ width: "12.5%" }}>Cmt</th>
                        <th style={{ width: "12.5%" }}>Paz</th>
                    </tr>
                </thead>
                <tbody>
                    {[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5].map(
                        (hour) => (
                            <tr>
                                <td className="text-sm font-bold">{hour < 10 ? "0" + hour : "" + hour}:00</td>
                                {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map(
                                    (day) => (
                                        <>
                                            <td className="border-b border-gray-200 p-3 text-sm">
                                                {calculateSpecialRequest(
                                                    day,
                                                    (hour < 10 ? "0" + hour : "" + hour) + ":00"
                                                )}
                                                &nbsp;
                                            </td>
                                        </>
                                    )
                                )}
                            </tr>
                        )
                    )}
                </tbody>

                <tfoot>
                    {
                        <tr>
                            <td className="text-sm font-bold">Toplam</td>
                            {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map(
                                (day) => (
                                    <>
                                        <td className="border-b border-gray-200 p-3 text-sm">
                                            {calculateDayRequest(day)}&nbsp;
                                        </td>
                                    </>
                                )
                            )}
                        </tr>
                    }
                </tfoot>
            </table>

            <div className="my-3 w-full border bg-white p-3 text-center text-sm font-bold text-gray-500 shadow">
                Total Request <br />~{" "}
                {analysis_list &&
                    analysis_list
                        .reduce((partialSum: number, r: any) => partialSum + calcRequest(r, true), 0)
                        .toLocaleString("tr-TR")}{" "}
                / Week
                <br />~{" "}
                {analysis_list &&
                    (
                        analysis_list.reduce((partialSum: number, r: any) => partialSum + calcRequest(r, true), 0) * 4
                    ).toLocaleString("tr-TR")}{" "}
                / Month
            </div>
        </>
    )
}

export { AnalyseList }
