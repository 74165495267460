let channelsconst: any = []

channelsconst["tatilbudur.com"] = {
    title: "Tatilbudur",
    logo: "/assets/channelLogos/tatilbudur.png",
}

channelsconst["m.tatilbudur.com"] = {
    title: "Tatilbudur (Mobil)",
    logo: "/assets/channelLogos/m_tatilbudur.png",
}

channelsconst["otelz.com"] = {
    title: "Otelz",
    logo: "/assets/channelLogos/otelz.png",
}

channelsconst["m.otelz.com"] = {
    title: "Otelz (Mobil)",
    logo: "/assets/channelLogos/m_otelz.png",
}

channelsconst["odamax.com"] = {
    title: "Odamax",
    logo: "/assets/channelLogos/odamax.png",
}
channelsconst["m.odamax.com"] = {
    title: "Odamax (Mobil)",
    logo: "/assets/channelLogos/m_odamax.png",
} 

channelsconst["etstur.com"] = {
    title: "Etstur",
    logo: "/assets/channelLogos/etstur.png",
}
channelsconst["hotels.com"] = {
    title: "Hotels",
    logo: "/assets/channelLogos/hotels.png",
}
channelsconst["m.hotels.com"] = {
    title: "Hotels (Mobil)",
    logo: "/assets/channelLogos/m_hotels.png",
}


channelsconst["tatilsepeti.com"] = {
    title: "Tatilsepeti",
    logo: "/assets/channelLogos/tatilsepeti.png",
}

channelsconst["jollytur.com"] = {
    title: "JollyTur",
    logo: "/assets/channelLogos/jollytur.png",
}
channelsconst["trivago.com"] = {
    title: "Trivago",
    logo: "/assets/channelLogos/trivago.png",
}

channelsconst["trivago.tatilbudur.com"] = {
    title: "Tatilbudur (Trivago)",
    logo: "/assets/channelLogos/trivago_tatilbudur.png",
}
channelsconst["trivago.agoda.com"] = {
    title: "Agoda (Trivago)",
    logo: "/assets/channelLogos/trivago_agoda.png",
}
channelsconst["trivago.bookandgo.com"] = {
    title: "Book & Go (Trivago)",
    logo: "/assets/channelLogos/trivago_bookandgo.png",
}
channelsconst["trivago.trip.com"] = {
    title: "Trip.com (Trivago)",
    logo: "/assets/channelLogos/trivago_trip.png",
}
channelsconst["trivago.zenhotels.com"] = {
    title: "Zen Hotels (Trivago)",
    logo: "/assets/channelLogos/trivago_zenhotels.png",
}
channelsconst["trivago.destinia.com"] = {
    title: "Destinia (Trivago)",
    logo: "/assets/channelLogos/trivago_destinia.png",
}

export { channelsconst }
