import axios from "axios";
import collect from "collect.js";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { Analysis } from "../../models/analysis";
import { ResultItem } from "../../redux/ResultSlice";
import { LayoutSettingsPanel } from "../../shared/aside/LayoutSettingsPanel";
import { MYSVG } from "../../shared/Helpers/MYSVG";

const AnalyseSummary: FC = () => {

    const url = useParams();
    const navigate = useNavigate();

    const analyse = useAppSelector(state => state.analysis.items)
    const [analyseTitle, setAnalyseTitle] = useState('')
    const [resultSummary, setResultSummary] = useState([])
    const [loading, setLoading] = useState(false)

    const night = (r: any) => (
        DateTime.fromISO(r.end_date).diff(DateTime.fromISO(r.start_date), 'days').days
    );

    useEffect(() => {
        if (url.id != undefined) {

            var analyseDetail = analyse.find(r => r.id == parseInt(url.id || ""))

            setAnalyseTitle(analyseDetail?.title || "")

            setLoading(true)
            axios.post("https://compare.hoteldoping.com/arg/api/result/summary", {
                analyse_id: url.id
            },).then((result: any) => {
                if (result.data !== undefined) {
                    setResultSummary(result.data)
                }
                setLoading(false)
            });

        }
    }, [url])

    const compareTitleConverter = (text: string) => {
        switch (text) {
            case "higher":
                return <div className='text-red-500'>Pahalı</div>;
            case "lower":
                return <div className='text-green-600'>Ucuz</div>;
            case "equal":
                return <div className='text-blue-500'>Eşit</div>;
            case "notavail":
                return <div className='text-gray-500'>Fiyatsız</div>;
            case "unknown":
                return <div className='text-gray-500'>Tüm Kanallarda Dolu</div>;

        }
    }

    const handleAnalyseSelect = (r: ResultItem) => {
        console.log(r);
        const analyse_id = r.analyse_id
        const scan_start = r.scan_start
        navigate("/analyse/result/" + analyse_id + "/" + scan_start + "/" + r.compare_status)
    }

    return (
        <>
            {loading && <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex place-items-center place-content-center">
                <div className="flex flex-col place-items-center place-content-center gap-2">
                    <MYSVG path="/assets/svg/loading.svg" svgClassName="h-10 w-10 animate-spin" />
                    <svg className="animate-spin -ml-1 mr-3 h-20 w-20 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            </div>}
            <div className='py-8 mb-5 -mt-10 rounded-lg grid grid-row-1 divide-y divide-y-1 '>
                <div>
                    <div className="flex flex-nowrap mb-5">
                        <h3 className="bigTitle flex-grow mb-3 text-white">
                            <MYSVG path='/assets/svg/search.svg' />
                            <span className='mx-2 text-white'>{analyseTitle}</span>
                        </h3>
                    </div>
                    {resultSummary.length == 0 && !loading && (<><div className="font-semibold mb-5 text-gray-500" role="alert">
                        Henüz bu analiz için tarama yapılmamış.
                    </div>
                        <a onClick={() => navigate('/analyse/index')} className="w-full py-2 px-2 flex flex-nowrap place-items-center place-content-center gap-1 rounded-md hover:shadow-md font-semibold cursor-pointer bg-sky-100  text-sky-500 hover:bg-sky-500 hover:text-white">
                            <MYSVG
                                path="/assets/svg/search2.svg"
                                className="mr-2"
                                svgClassName="h-5 w-5"
                            />
                            <div>Tüm Analizler</div>
                        </a>
                    </>)
                    }
                    {resultSummary && collect(resultSummary)
                        .groupBy((item: any, key) => DateTime.fromISO(item.scan_start).setLocale('tr-TR').toFormat('dd MMMM yyyy cccc'))
                        .map((timeGroupRows: any, dayTitle: any) => {
                            return (
                                <div className="bg-white border rounded-lg p-5 mb-8 shadow-lg">
                                    <div>
                                        <h3 className="bigTitle  text-2xl mb-7 border-b -mx-5 px-5 py-2">
                                            <div>{dayTitle}</div>
                                        </h3>
                                    </div>
                                    <div className="flex flex-col gap-16">
                                        {timeGroupRows
                                            .groupBy((item: any) => DateTime.fromISO(item.start_date).setLocale('tr-TR').toFormat('dd MMMM yyyy cccc') + " / " + night(item) + ' Gece / ' + item.adult_count + " Yetişkin")
                                            .map((searchDates: any, searchDateTitle: any) => (
                                                <div className="flex flex-col">
                                                    <h3 className="flex gap-1 place-items-center mb-5 pb-2 font-semibold border-b text-sky-600"><MYSVG
                                                        path="/assets/svg/search.svg"
                                                        className="mr-2"
                                                        svgClassName="h-4 w-4"
                                                    /> Tarama Kriteri : {searchDateTitle}</h3>
                                                    <div className="mx-5 md:mx-0 grid grid-col-1 md:grid-cols-4 gap-5">
                                                        {searchDates && searchDates.sortBy('compare_status')
                                                            .groupBy((item: ResultItem) => item.compare_status)
                                                            .map((compareGroupRows: any, compareTitle: any) => (
                                                                <div key={timeGroupRows.id}>
                                                                    <h3 className="font-semibold mb-2 border-b pb-2">{compareTitleConverter(compareTitle)}</h3>
                                                                    <div className="p-5 flex rounded-md bg-white border border-gray-300  text-black shadow-md hover:shadow-lg transition-shadow ease-in-out">
                                                                        <div className="w-full flex flex-wrap divide-y-2 gap-1">

                                                                            {compareGroupRows && compareGroupRows.sortBy('scan_start').map((compareRow: any) => (
                                                                                <div onClick={() => handleAnalyseSelect(compareRow)} className=" cursor-pointer w-full flex flex-nowrap place-items-center text-md font-semibold text-gray-600 ">
                                                                                    <div className="flex place-items-center ">
                                                                                        <MYSVG
                                                                                            path="/assets/svg/clock.svg"
                                                                                            className="mr-2"
                                                                                            svgClassName="h-4 w-4"
                                                                                        />
                                                                                        <span>{DateTime.fromISO(compareRow.scan_start).setLocale('tr-TR').toFormat('HH:mm')}</span>
                                                                                    </div>
                                                                                    <div className="flex-grow"></div>
                                                                                    <div>
                                                                                        <div className="flex place-items-center ">
                                                                                            <MYSVG
                                                                                                path="/assets/svg/build.svg"
                                                                                                className="mr-2"
                                                                                                svgClassName="h-4 w-4"
                                                                                            />
                                                                                            {compareRow.compare_status_count && compareRow.compare_status_count} Tesis
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}

                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                            ).values()}

                                                    </div>
                                                </div>
                                            )).values()
                                        }
                                    </div>

                                </div>
                            )
                        }).values()}
                </div>
            </div>
        </>)
}
export { AnalyseSummary };