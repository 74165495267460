import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { MYSVG } from "../Helpers/MYSVG";
import { setAllShow, setPropertyListType } from "../../redux/LayoutSlice";
import clsx from 'clsx';
import { pListType } from "../../models/layout";

const LayoutSettingsPanel: FC = (props: any) => {

    const propertyListType = useAppSelector((state) => state.layout.propertyListType)
    const allShow = useAppSelector((state) => state.layout.allShow)
    const dispatch = useAppDispatch()

    return (
        <div className="flex flex-nowrap text-sm font-medium text-gray-400 gap-1">
            <div>
                <div className={clsx("p-2 flex place-content-center items-center border border-blue-200 rounded-md shadow-sm hover:shadow-md hover:shadow-blue-100 text-gray-500 bg-slate-50 hover:border-solid cursor-pointer",
                    propertyListType === pListType.list_big ? "text-sky-700 shadow-md shadow-sky-100" : "")}
                    onClick={() => { dispatch(setAllShow(!allShow)) }}>
                    {!allShow ?
                        (<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M15.707 4.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0zm0 6a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L10 14.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>) : (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                            </svg>
                        )}
                </div>
            </div>
        </div >)
}

export { LayoutSettingsPanel }