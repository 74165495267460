import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LayoutState, pListType } from '../models/layout';


const initialState = {
    propertyListType: pListType.list_more, // "list_big" "list_more" "boxed"
    allShow: false
} as LayoutState

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setPropertyListType: (state, { payload }) => {
            state.propertyListType = payload;
        },
        setAllShow: (state, { payload }) => {
            state.allShow = payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setPropertyListType, setAllShow } = layoutSlice.actions

export default layoutSlice.reducer