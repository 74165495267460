import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeFormStep, fetchAnalysis, reset } from '../../../redux/AnalysisSlice';
import { showToast } from '../../../redux/ToastSlice';
import { MYSVG } from '../../../shared/Helpers/MYSVG';
import WizardContent from './WizardContent';
import WizardSidebar from './WizardSidebar';

const WizardWrapper = () => {

    const dispatch = useAppDispatch();
    const analysisValues = useAppSelector(state => state.analysis)

    const navigate = useNavigate();
    let params = useParams();

    const handleFinish = async (e: any) => {
        e.preventDefault();
        if (params.id != null) {
            axios.put(`https://compare.hoteldoping.com/arg/api/analysis/update/${params.id}`, {
                title: analysisValues.title,
                period_start: analysisValues.period_start,
                period_repeat: analysisValues.period_repeat,
                date_others: analysisValues.date_others,
                date_today: analysisValues.date_today ? 1 : 0,
                date_tomorrow: analysisValues.date_tomorrow ? 1 : 0,
                date_weekend: analysisValues.date_weekend ? 1 : 0,
                property_ids: analysisValues.property_ids,
                period_days:analysisValues.period_days,
                period_times:analysisValues.period_times
            }).then((r: any) => {
                dispatch(fetchAnalysis());
                dispatch(reset());
                navigate("/dashboard")
            });
        }
        else {
            let params = {
                title: analysisValues.title,
                period_repeat: analysisValues.period_repeat,
                period_start: analysisValues.period_start,
                adultCount: analysisValues.adultCount,
                date_others: analysisValues.date_others,
                date_today: analysisValues.date_today ? 1 : 0,
                date_tomorrow: analysisValues.date_tomorrow ? 1 : 0,
                date_weekend: analysisValues.date_weekend ? 1 : 0,
                property_ids: analysisValues.property_ids,
                period_days:analysisValues.period_days,
                period_times:analysisValues.period_times
            }
            axios.post("https://compare.hoteldoping.com/arg/api/analysis/store", params)
                .then((r: any) => {
                    dispatch(fetchAnalysis());
                    dispatch(reset());
                    navigate("/dashboard")
                });

        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        var canContinue = false;
        var canFinish = false;
        if (analysisValues.formStep === 0 &&
            (
                analysisValues.title !== "" &&
                analysisValues.period_start !== "" &&
                analysisValues.period_repeat !== undefined &&
                analysisValues.period_repeat >= 0 &&
                analysisValues.adultCount! >= 0
            )
        ) {
            canContinue = true
        }
        if (analysisValues.formStep === 1 &&
            (
                (analysisValues.date_today || analysisValues.date_tomorrow || analysisValues.date_weekend) ||
                analysisValues.date_others.length > 0
            )
        ) {
            canContinue = true
        }

        if (analysisValues.formStep === 2 &&
            (
                analysisValues.title !== "" &&
                analysisValues.period_start !== "" &&
                analysisValues.period_repeat !== undefined &&
                analysisValues.period_repeat >= 0 &&
                analysisValues.adultCount! >= 0 &&
                (analysisValues.date_today || analysisValues.date_tomorrow || analysisValues.date_weekend || analysisValues.date_others.length > 0)
                && analysisValues.property_ids?.length! > 0
            )
        ) {
            canFinish = true
        }

        if (canContinue)
            dispatch(changeFormStep(analysisValues.formStep + 1))
        if (canFinish) {
            handleFinish(e)
            dispatch(showToast({ status: "success", message: "Analiz güncellendi" }));
        }

    }


    return (
        <>
            <div className='flex'>
                <div className='mr-5 w-96  '>
                    <WizardSidebar />
                </div>
                <div className='bg-white text-black rounded-lg border flex-grow'>
                    <WizardContent />
                    <div className="flex px-8 pb-5">
                        <div>
                            <button onClick={() => { dispatch(changeFormStep(0)) }}
                                className='bg-gray-100 gap-2 border border-gray-300  hover:bg-gray-200 text-gray-500 py-2 px-3 text-sm align-middle font-semibold rounded flex items-center'>
                                <MYSVG path="/assets/svg/back.svg" svgClassName="h-5 w-5 " /> GERİ
                            </button>
                        </div>
                        <div className='flex-grow'></div>
                        <div>
                            <button className='bg-blue-700 gap-2  hover:bg-blue-800 p-8  hover:shadow-md text-white py-2 px-3 text-sm align-middle font-semibold rounded flex items-center'
                                onClick={(e) => { handleSubmit(e) }}>
                                <MYSVG path="/assets/svg/continue.svg" svgClassName="h-5 w-5 " /> DEVAM ET
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WizardWrapper