import clsx from 'clsx'; 
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { addAllPropertyItems, addPropertyItem, removeAllPropertyItems, removePropertyItem } from '../../../../redux/AnalysisSlice';
import { fetchProperties } from '../../../../redux/PropertySlice';

const PropertySelector = () => {

    const [inputSearchValue, setInputSearchValue] = useState("");
    const propertyItems = useAppSelector((state) => state.property)
    const analysisValues = useAppSelector(state => state.analysis)
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchProperties(""))
    }, [])

    const handleGetSelectedCheckBox = (id: number): boolean => {
        if (analysisValues.property_ids?.find(f => f.id == id) != null) {
            return true;
        }
        return false;
    }

    const handleChangeInputSearch = (e: any) => {
        setInputSearchValue(e.target.value)
    }

    const handleSelectAll = () => {
        let filteredProperties: any = propertyItems.items.filter(property => property.title?.toLowerCase().includes(inputSearchValue.toLowerCase())
            || property.city?.toLowerCase().includes(inputSearchValue.toLowerCase())
            || property.town?.toLowerCase().includes(inputSearchValue.toLowerCase())
            || property.contract_type?.toLowerCase().includes(inputSearchValue.toLowerCase())
            || property.property_concept?.toLowerCase().includes(inputSearchValue.toLowerCase())
        );
        dispatch(addAllPropertyItems(filteredProperties));
    }

    const handleRemoveAll = () => {
        dispatch(removeAllPropertyItems(analysisValues.property_ids));
    }

    const handleSelectCheckBox = (data: string, id: number, e: any) => {
        if (e.target.checked) {
            dispatch(addPropertyItem({ id }))
        }
        else {
            dispatch(removePropertyItem(id))
        }
    }
    return (
        <>
            <div className='px-8 py-4 border-b border-gray-200  place-items-center justify-between '>
                <h1 className='text-md mb-2 font-semibold text-gray-700 text-2xl'>
                    Tesis bilgileri
                </h1>
                <p className='text-xs font-normal text-gray-400'>Lütfen taranacak tesisleri seçiniz.</p>
            </div>
            <div className='p-8 pb-0 mb-5 overflow-auto h-30'>
                <div>
                    <div className='flex gap-4 mb-3 ml-1 items-center'>
                        <button className='bg-blue-700 p-2 text-white rounded text-sm hover:bg-blue-800'
                            onClick={() => handleSelectAll()}>Tümünü seç</button>
                        <button className='bg-blue-700 p-2 text-white rounded text-sm hover:bg-blue-800'
                            onClick={() => handleRemoveAll()}>Seçimleri kaldır</button>
                    </div>
                    <div className="relative">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg className="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                        </div>

                        <input type="text" className="border text-gray-500 font-semibold text-sm rounded-lg rounded-bl-none rounded-br-none w-full pl-10 p-2.5 focus:border-gray-400" placeholder="Tesis ara... "
                            value={inputSearchValue}
                            onChange={(e) => handleChangeInputSearch(e)} />
                    </div>
                    <ul className="overflow-y-auto  h-80 text-sm text-gray-700 bg-white rounded rounded-tl-none rounded-tr-none -mt-1 border" >
                        {
                            propertyItems.items.filter(property => property.title?.toLowerCase().includes(inputSearchValue.toLowerCase())
                                || property.city?.toLowerCase().includes(inputSearchValue.toLowerCase())
                                || property.town?.toLowerCase().includes(inputSearchValue.toLowerCase())
                                || property.contract_type?.toLowerCase().includes(inputSearchValue.toLowerCase())
                                || property.property_concept?.toLowerCase().includes(inputSearchValue.toLowerCase())
                            ).map((data) => {
                                return (
                                    <li className=''>
                                        <div className={clsx("flex border-b border-gray-200 items-center hover:bg-gray-300", handleGetSelectedCheckBox(data.id) ? 'bg-gray-100' : '')}>
                                            <label className="flex gap-3 p-3 w-full h-full text-sm font-medium text-gray-500">
                                                <input type="checkbox" className="w-4 h-4 cursor-pointer"
                                                    onChange={(e) => handleSelectCheckBox(data.title, data.id, e)}
                                                    checked={handleGetSelectedCheckBox(data.id)} />
                                                <div className='flex w-full'>
                                                    <div >{data.title}</div>
                                                    <div className='flex-grow'></div>
                                                    <div className='text-xs text-gray-400 font-normal'  >[ {data.property_concept} ] [ {data.contract_type} ] {data.town} / {data.city}</div>
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}

export default PropertySelector
