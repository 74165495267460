import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { AuthState, loginModel, registerModel, UserModel } from '../models/auth';

const initialState = {
  isLoading: false,
  error: false,
  errorMessage: "",
  isLoggedIn: false,
  roles: [],
  access_token: "",
  refresh_token: "",
  user: {} as UserModel
} as AuthState

export const userMe = createAsyncThunk('user/me',
  async (_, { getState, dispatch }) => {
    const state: any = getState();

    dispatch(setLoading(true))

    return await axios.post("https://compare.hoteldoping.com/arg/auth/me", {}, {
      headers: {
        "Authorization": "Bearer " + state.access_token
      }
    }).then((result) => result.data)
      .catch((err) => {

        dispatch(setLoading(false))
        dispatch(setError(err.message))

      });
  }
)

export const userLogin = createAsyncThunk('user/login',
  async (parameters: loginModel, { dispatch }) => {

    dispatch(setLoading(true))
    dispatch(setError(null))

    return await axios.post("https://compare.hoteldoping.com/arg/auth/login", {
      email: parameters.email,
      password: parameters.password
    }).then((result) => {

      dispatch(setLoading(false))
      if (result.status === 200)
        return result.data;

    }).catch((err) => {

      dispatch(setLoading(false))
      dispatch(setError(err.response.data.message ? err.response.data.message : err.message))

    });
  }
)


export const userRegister = createAsyncThunk('user/register',
  async (parameters: registerModel, { dispatch }) => {

    dispatch(setLoading(true))
    dispatch(setError(null))

    return await axios.post("https://compare.hoteldoping.com/arg/auth/register", parameters).then((result) => {

      dispatch(setLoading(false))
      if (result.status === 200) {
        dispatch(userLogin({ email: parameters.email, password: parameters.password }));
        return result.data;
      }
      if (result.status === 400) {
        dispatch(setError(result.data))
      }

    }).catch((err) => {
      dispatch(setLoading(false))
      dispatch(setError(err.response.data.message ? err.response.data.message : err.message))

    });
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {

    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },

    setError: (state, { payload }) => {
      if (payload) {
        state.error = true;
        state.errorMessage = payload;
      } else {
        state.error = false;
        state.errorMessage = "";
      }
    },

    setIsLoggedIn: (state, { payload }) => {
      state.isLoading = payload;
    },

    logout: (state, { payload }) => {
      state.isLoggedIn = false;
      state.user = {} as UserModel;
      //document.location.reload()
    }

  },
  extraReducers: (builder) => {

    builder.addCase(userMe.fulfilled, (state, action) => {

      if (action.payload)
        state.user = action.payload;

    });

    builder.addCase(userLogin.fulfilled, (state, action) => {

      if (action.payload) {
        state.error = false;
        state.errorMessage = "";
        state.access_token = action.payload.authToken;
        state.refresh_token = action.payload.refreshToken;
        state.isLoggedIn = true;
      }
    })

  },
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, setIsLoggedIn, logout } = authSlice.actions

export default authSlice.reducer