import axios from "axios"
import { DateTime } from "luxon"
import { FC, useEffect, useRef, useState } from "react"
import Skeleton from 'react-loading-skeleton'
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Analysis } from "../../models/analysis"
import { fetchResult, ResultFilter, ResultQueryParams } from "../../redux/ResultSlice"
import { LayoutSettingsPanel } from "../../shared/aside/LayoutSettingsPanel"
import { MYSVG } from "../../shared/Helpers/MYSVG"
import { Pagination } from "../../shared/pagination/pagination"
import { Searchbar } from "../Property/Searchbar"
import { AnalyseAside } from "./AnalyseAside"
import AnalyseResultRow from "./AnalyseResultRow"


const AnalyseResult: FC = () => {

    const dispatch = useAppDispatch()

    const urlParams = useParams();

    const result = useAppSelector(state => state.result)
    const analyse = useAppSelector(state => state.analysis)

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [search, setSearch] = useState("");
    const [selectedAnalyse, setSelectedAnalyse] = useState<Analysis | undefined>(undefined);
    const searchRef = useRef(null);
    const [resultFilter, setResultFilter] = useState<ResultFilter | null>(null);

    const [sortField, setSortField] = useState('tb_rate_by_lower')
    const [sortDir, setSortDir] = useState('desc nulls last')
    const [extraFilter, setExtraFilter] = useState({ compare_status: urlParams.compare_status })
    const [resultSummary, setResultSummary] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const foundAnalyse = analyse.items.find(r => r.id.toString() == urlParams.id!)
        if (foundAnalyse) {
            setSelectedAnalyse(foundAnalyse)
        }

        setLoading(true)
        axios.post("https://compare.hoteldoping.com/arg/api/result/summary", {
            analyse_id: urlParams.id
        }).then((result: any) => {
            if (result.data !== undefined) {
                setResultSummary(result.data)
            }
            setLoading(false)
        });
    }, [])

    useEffect(() => {

        if (urlParams.id != undefined && urlParams.scan_start != undefined) {

            const resultParams: ResultQueryParams = {
                analyse_id: urlParams.id,
                scan_start: urlParams.scan_start,
                sorting: {
                    column: sortField, // tb_rate_by_lower // property_priority
                    direction: sortDir
                },
                paginator: {
                    page: currentPage,
                    pageSize: pageSize
                },
                searchTerm: search,
                filter: { ...resultFilter, ...extraFilter }
            }
            dispatch(fetchResult(resultParams))
        }

    }, [currentPage, pageSize, search, resultFilter, dispatch, urlParams, sortField, sortDir, extraFilter])



    return (
        <>
            <main className="pb-8">
                <div className="mx-auto max-w-3xl lg:max-w-7xl">
                    <h1 className="sr-only">Page title</h1>
                    {/* Main 3 column grid */}
                    <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-4">
                        {/* Left column */}
                        <div className="grid grid-cols-1 gap-4 lg:col-span-8">
                            <section aria-labelledby="section-1-title">
                                <h2 className="sr-only" id="section-1-title">
                                    Section title
                                </h2>
                                <div className="overflow-hidden rounded-lg bg-white shadow">
                                    <div className="p-6">


                                        <div className="flex flex-nowrap">
                                            <div className="flex flex-col">
                                                <h3 className="bigTitle  text-sky-700 flex-grow">
                                                    <MYSVG path='/assets/svg/search.svg' />
                                                    <span className='mx-2'>{selectedAnalyse?.title}</span>
                                                </h3>
                                                <div className="my-2 font-semibold ml-10 text-xl text-sky-500">{DateTime.fromISO(urlParams.scan_start!).toFormat('dd MMMM yyyy cccc HH:mm', { locale: 'tr' })} Taraması</div>
                                            </div>
                                            <div className="flex-grow"></div>
                                            <LayoutSettingsPanel />
                                        </div>

                                        <Searchbar
                                            search={search}
                                            setSearch={setSearch}
                                            setCurrentPage={setCurrentPage}
                                            searchRef={searchRef}
                                        />
                                        {result.loading && ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((r, index) => (
                                            <div key={index} className="skeletonMoreContainer">
                                                <Skeleton count={1} />
                                            </div>
                                        )))}
                                        <>
                                            {!result.loading && (
                                                <div className="grid grid-cols-1">

                                                    {result.data && result.data.items && result.data.items.map((row: any) => {
                                                        return <AnalyseResultRow key={row.id} resultRow={row} />
                                                    })}
                                                </div>
                                            )}

                                            {(!search || search.length == 0 || (search && search.length < 3)) && (

                                                <div className="flex flex-wrap items-center mt-5 ">
                                                    <div className='tfoot d-flex flex-md-stack float-left'>
                                                        <div className='d-flex flex-md-stack'>
                                                            <select onChange={(e) => setPageSize(parseInt(e.target.value))}
                                                                className="border py-1 pr-6 pl-1 bg-slate-100 rounded font-normal text-gray-600" value={pageSize}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select>
                                                            <span className='paginateDescription'></span>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow my-10 md:my-0"></div>
                                                    <div>
                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={currentPage}
                                                            totalCount={result.data?.total}
                                                            pageSize={pageSize}
                                                            siblingCount={1}
                                                            onPageChange={(page: number) => setCurrentPage(page)}
                                                        />
                                                    </div>
                                                </div>)}
                                        </>

                                    </div>
                                </div>
                            </section>
                        </div>

                        {/* Right column */}
                        <div className="grid grid-cols-1 gap-4 lg:col-span-4">
                            <section aria-labelledby="section-2-title">
                                <h2 className="sr-only" id="section-2-title">
                                    Section title
                                </h2>
                                <div className="overflow-hidden rounded-lg bg-white shadow">
                                    <div className="p-6">

                                        <AnalyseAside
                                            setResultFilter={setResultFilter}
                                            resultSummary={resultSummary}
                                            resultFilter={resultFilter}
                                            extraFilter={extraFilter}
                                            sortField={sortField}
                                            sortDir={sortDir}
                                            setSortField={setSortField}
                                            setSortDir={setSortDir}
                                            extraFilterHandle={setExtraFilter}
                                            pageHandle={setCurrentPage} />


                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export { AnalyseResult }

