import { Outlet } from "react-router-dom"
import Nav from "./Header/Nav"

import '../index.css';
import 'react-loading-skeleton/dist/skeleton.css'

const FullLayout = () => {
    return (
        <>
            <div className='h-10'></div>
            <div className="mx-20 ">
                <Outlet />
            </div>
        </>
    )
}

export { FullLayout }