import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAppDispatch } from '../../hooks'
import { logout } from '../../redux/AuthSlice'

export function Logout() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(logout(undefined));
  })

  return ( 
    <Routes>
      <Route>  
        <Route path='*' element={<Navigate to='/' />} />
      </Route>
    </Routes>
  )
}
