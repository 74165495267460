import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

export interface InventoryItem {
  id: number;
  title: string;
  property_type?: any;
  active_channels: string[];
  search_parameters: {};
  address?: any;
  city: string;
  state: string;
  town: string;
  country: string;
  contract_type: string;
  property_concept?: any;
  property_priority: number;
  contract_person: string;
  property_note: string;
  created_at: Date;
  updated_at: Date;
}

export interface InventoryResponse {
  total: number;
  items: InventoryItem[];
  cacheKey: string;
}

export interface Paginator {
  page: number,
  pageSize: number
}

export interface InventoryFilter {
  searchKey: string
}

export interface InventoryQueryParams {
  sorting: any | undefined,
  paginator: Paginator | undefined,
  searchTerm: string | undefined,
  filter: any | undefined,
}

export interface InventoryState {
  data: InventoryResponse | null;
  loading: boolean;
  error: string;
}


const initialState: InventoryState = {
  data: null,
  loading: false,
  error: ""
}

export const fetchInventoriesWithPagination = createAsyncThunk('fetchInventory', async (parameters: InventoryQueryParams) => {
  const response = await axios.post<InventoryResponse>("https://compare.hoteldoping.com/arg/api/propertyInventory/list", parameters)
  return response.data;
});


export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(fetchInventoriesWithPagination.pending, (state, action) => {
      state.loading = true
      state.error = ""
    })

    builder.addCase(fetchInventoriesWithPagination.fulfilled, (state, action: PayloadAction<InventoryResponse>) => {
      state.loading = false
      state.data = action.payload
    })

    builder.addCase(fetchInventoriesWithPagination.rejected, (state, action) => {
      state.loading = false
      state.error = "Error fetch data"
    })
  }

})

export default inventorySlice.reducer

